import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "@/pages/LoginPage";
import SideMenu from "@/pages/SideMenu";
import TransactionsPage from "@/pages/TransactionsPage";
import TransactionDetailPage from "@/pages/TransactionDetailPage";
import MessagesPage from "@/pages/MessagesPage";
import InquiriesPage from "@/pages/InquiriesPage";
import InquiryDetailPage from "@/pages/InquiryDetailPage";
import UsersPage from "@/pages/UsersPage";
import UserDetailPage from "@/pages/UserDetailPage";
import ItemNewPage from "@/pages/ItemNewPage";
import TransferApplicationsPage from "@/pages/TransferApplicationsPage";
import ItemsPage from "@/pages/ItemsPage";
import ItemEditPage from "@/pages/ItemEditPage";
import ItemDetailPage from "@/pages/ItemDetailPage";

const routes = [
  {
    name: "root",
    path: "/",
    redirect: "/login",
  },
  {
    name: "LoginPage",
    path: "/login",
    component: LoginPage,
  },
  {
    path: "/",
    component: SideMenu,
    children: [
      {
        path: "transactions",
        children: [
          {
            name: "TransactionsPage",
            path: "",
            component: TransactionsPage,
          },
          {
            name: "TransactionDetailPage",
            path: ":transaction_id",
            component: TransactionDetailPage,
          },
        ],
      },
      {
        path: "inquiries",
        children: [
          {
            name: "InquiriesPage",
            path: "",
            component: InquiriesPage,
          },
          {
            name: "InquiriesDetailPage",
            path: ":inquiry_id",
            component: InquiryDetailPage,
          },
        ],
      },
      {
        path: "users",
        children: [
          {
            name: "UsersPage",
            path: "",
            component: UsersPage,
          },
          {
            name: "UsersDetailPage",
            path: ":user_id",
            component: UserDetailPage,
          },
        ],
      },
      {
        path: "items",
        children: [
          {
            name: "ItemsPage",
            path: "",
            component: ItemsPage,
          },
          {
            name: "ItemDetailPage",
            path: ":item_id",
            component: ItemDetailPage,
          },
          {
            name: "ItemEditPage",
            path: ":item_id/edit",
            component: ItemEditPage,
          },
          {
            name: "ItemsNewPage",
            path: "new",
            component: ItemNewPage,
          },
        ],
      },
      { name: "MessagesPage", path: "messages", component: MessagesPage },
      {
        path: "transfer-applications",
        children: [
          {
            name: "TransferApplicationsPage",
            path: "",
            component: TransferApplicationsPage,
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
});

export default router;
