<template>
  <div class="items">
    <div class="search-form col-sm-12 py-4 row">
      <div class="col-sm-7">
        <div class="row mb-4">
          <label for="id" class="col-sm-2 col-form-label col-form-label-lg">
            ID
          </label>
          <div class="col-sm-5">
            <input
              type="text"
              v-model="id"
              id="id"
              class="form-control form-control-lg col-sm-12"
            />
          </div>
        </div>
        <div class="row mb-4">
          <label for="name" class="col-sm-2 col-form-label col-form-label-lg">
            商品名
          </label>
          <div class="col-sm-5">
            <input
              type="text"
              v-model="name"
              id="name"
              class="form-control form-control-lg col-sm-12"
            />
          </div>
        </div>
        <div class="row mb-4">
          <label
            for="category"
            class="col-sm-2 col-form-label col-form-label-lg"
          >
            種類
          </label>
          <div class="col-sm-5">
            <input
              type="text"
              v-model="category"
              id="category"
              class="form-control form-control-lg col-sm-12"
            />
          </div>
        </div>
        <div class="row mb-4">
          <label for="brand" class="col-sm-2 col-form-label col-form-label-lg">
            ブランド
          </label>
          <div class="col-sm-5">
            <input
              type="text"
              v-model="brand"
              id="brand"
              class="form-control form-control-lg col-sm-12"
            />
          </div>
        </div>
        <div class="row">
          <label for="type" class="col-sm-2 col-form-label col-form-label-lg">
            タイプ
          </label>
          <div class="col-sm-5">
            <input
              type="text"
              v-model="type"
              id="type"
              class="form-control form-control-lg col-sm-12"
            />
          </div>
          <label for="status" class="col-sm-2 col-form-label col-form-label-lg">
            状態
          </label>
          <div class="col-sm-3">
            <select
              v-model="is_public"
              id="is_public"
              class="form-control form-control-lg col-sm-12"
            >
              <option
                v-for="is_public_item in IS_PUBLIC_OPTION"
                :key="is_public_item.value"
                :value="is_public_item.value"
              >
                {{ is_public_item.text }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        class="col-sm-5 row flex-column justify-content-between align-items-end"
      >
        <router-link
          class="btn btn-primary btn-lg ml-auto w-25"
          :to="'/items/new'"
          target="_blank"
        >
          新規登録
        </router-link>
        <button
          class="btn btn-success btn-lg ml-auto w-25"
          @click="searchSubmit()"
        >
          検索
        </button>
      </div>
    </div>
    <div class="items-wrapper">
      <table v-if="items.length" class="table table-striped table-bordered">
        <thead class="thead-dark">
          <th>ID</th>
          <th>ステータス</th>
          <th>状態</th>
          <th>商品名</th>
          <th>種類</th>
          <th>ブランド</th>
          <th>タイプ</th>
          <th>金額</th>
          <th>試奏料金1W</th>
          <th>試奏料金2W</th>
          <th>試奏料金4W</th>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>
              <router-link :to="`/items/${item.id}`" target="_blank">
                {{ item.id }}
              </router-link>
            </td>
            <td>
              {{ STATUS_LIST[item.status] }}
            </td>
            <td
              :class="{ 'font-weight-bold text-danger': item.is_public === 0 }"
            >
              {{ IS_PUBLIC_LIST[item.is_public] }}
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.item_category_name }}</td>
            <td>{{ item.brand }}</td>
            <td>{{ item.type }}</td>
            <td>&yen;{{ item.price?.toLocaleString() }}</td>
            <td>&yen;{{ Number(item.trial_price_1week).toLocaleString() }}</td>
            <td>&yen;{{ Number(item.trial_price_2week).toLocaleString() }}</td>
            <td>&yen;{{ Number(item.trial_price_4week).toLocaleString() }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="footer" v-if="page_count">
      <div class="footer-content">
        <div class="items-count">計{{ items_count }}件</div>
        <paginate
          v-model="page_num"
          :page-count="page_count"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'"
          :page-range="6"
          :hide-prev-next="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate-next";

export default {
  data() {
    return {
      id: "",
      name: "",
      category: "",
      brand: "",
      type: "",
      is_public: -1,
      IS_PUBLIC_OPTION: [
        { text: "", value: -1 },
        { text: "停止中", value: 0 },
        { text: "公開中", value: 1 },
      ],
      items: [],
      items_count: 0,
      page_num: 1,
      page_count: 0,
      STATUS_LIST: {
        0: "販売中",
        1: "試奏中",
        2: "売り切れ",
        3: "下書き",
      },
      IS_PUBLIC_LIST: {
        0: "停止中",
        1: "公開中",
      },
    };
  },
  components: {
    paginate: Paginate,
  },
  mounted() {
    this.userInfo = this.$store.getters["userInfo/userInfo"];
  },
  computed: {},
  watch: {
    page_num(page_num) {
      this.searchSubmit(page_num);
    },
  },
  methods: {
    searchSubmit(page_num = 1) {
      const ids = this.id === "" ? [] : this.id.split(/\s+/);
      const names = this.name === "" ? [] : this.name.split(/\s+/);
      const categories = this.category === "" ? [] : this.category.split(/\s+/);
      const brands = this.brand === "" ? [] : this.brand.split(/\s+/);
      const types = this.type === "" ? [] : this.type.split(/\s+/);

      const data = {
        ids,
        names,
        categories,
        brands,
        types,
        is_public: this.is_public,
        page_num: page_num,
      };

      this.isAxiosWait = true;
      this.$axios
        .post(
          `/getItemListForAdmin`,
          { data },
          { headers: { Authorization: `Bearer ${this.userInfo.accessToken}` } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }
          this.items = data.items;
          this.items_count = data.total;
          this.page_count = data.last_page;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isAxiosWait = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.items {
  font-size: 3rem;
}
.items-wrapper {
  width: 100%;
  margin: auto;
  margin-bottom: 2rem;
  overflow-x: scroll;
  font-size: 1.5rem;
  white-space: nowrap;
}
thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.pagination {
  margin: 2rem 0;
  justify-content: center;
}
.footer {
  position: sticky;
  bottom: 0;
  height: 9rem;
  background-color: white;
  border-top: 1px #646464 solid;
}
.footer-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.items-count {
  position: absolute;
  left: 3rem;
}
</style>
