<template>
  <div class="login-header">
    <img
      src="@/assets/images/gannon_icon.png"
      alt="wanon アイコン"
      class="wanon-icon"
    />
  </div>
</template>

<style lang="scss" scoped>
.login-header {
  text-align: center;
}
.wanon-icon {
  height: 10rem;
  margin: 3rem;
}
</style>

<script>
export default {};
</script>
