<template>
  <div class="user-detail h3 p-5 mx-auto">
    <div class="user-detail-area">
      <div class="row align-items-center mb-5">
        <div class="col-8 row align-items-center">
          <div class="font-weight-bold col-2 text-right"></div>
          <div class="col-10">
            <input
              type="text"
              v-model="name"
              id="name"
              class="form-control form-control-lg col-sm-12"
            />
          </div>
        </div>
        <div class="col-4 row align-items-center">
          <label
            for="user_id"
            class="col-sm-2 col-form-label col-form-label-lg text-center"
          >
            ユーザID
          </label>
          <div class="col-sm-5">
            <input
              type="text"
              v-model="user_id"
              id="user_id"
              class="form-control form-control-lg col-sm-10"
            />
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-5">
        <label
          for="status"
          class="col-sm-2 col-form-label col-form-label-lg text-center"
        >
          ステータス
        </label>
        <div class="col-sm-4">
          <select
            v-model="status"
            id="status"
            class="form-control form-control-lg"
            disabled
          >
            <option
              v-for="item in STATUS_OPTION"
              :key="item.value"
              :value="item.value"
            >
              {{ item.text }}
            </option>
          </select>
        </div>
        <label
          for="is_public"
          class="col-sm-2 col-form-label col-form-label-lg text-center"
        >
          公開<br />
          ステータス
        </label>
        <div class="col-sm-4">
          <select
            v-model="is_public"
            id="is_public"
            class="form-control form-control-lg"
            disabled
          >
            <option
              v-for="item in IS_PUBLIC_OPTION"
              :key="item.value"
              :value="item.value"
            >
              {{ item.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="row align-items-center mb-5">
        <div class="col-12 row align-items-end">
          <label class="col-sm-2 col-form-label col-form-label-lg text-center">
            商品画像
          </label>
          <br />
          <div class="col-12">
            <input
              type="file"
              name="item_images"
              id="item-images"
              multiple
              accept="image/*"
              ref="file"
              @change="changeImages($event)"
            />
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-5">
        <label
          for="item_category_id"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          商品種類
        </label>
        <select
          v-model="item_category_id"
          id="item_category_id"
          class="form-control form-control-lg col-sm-3"
        >
          <option value="-1"></option>
          <option
            v-for="item in item_categories"
            :key="item.item_category_id"
            :value="item.item_category_id"
          >
            {{ item.item_category_name }}
          </option>
        </select>
        <label
          for="brand"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          ブランド
        </label>
        <input
          type="text"
          v-model="brand"
          id="brand"
          class="form-control form-control-lg col-sm-3"
        />
        <label
          for="type"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          タイプ
        </label>
        <input
          type="text"
          v-model="type"
          id="type"
          class="form-control form-control-lg col-sm-3"
        />
      </div>
      <div class="row align-items-center mb-5">
        <label
          for="color"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          カラー
        </label>
        <input
          type="text"
          v-model="color"
          id="color"
          class="form-control form-control-lg col-sm-3"
        />
        <label
          for="body_type"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          ボディ<br />タイプ
        </label>
        <input
          type="text"
          v-model="body_type"
          id="body_type"
          class="form-control form-control-lg col-sm-3"
        />
        <label
          for="pattern"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          柄
        </label>
        <input
          type="text"
          v-model="pattern"
          id="pattern"
          class="form-control form-control-lg col-sm-3"
        />
      </div>
      <div class="row align-items-center mb-5">
        <label
          for="condition_code"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          商品の状態
        </label>
        <select
          v-model="condition_code"
          id="condition_code"
          class="form-control form-control-lg col-sm-3"
        >
          <option
            v-for="item in CONDITION_CODE_OPTION"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </option>
        </select>
        <label
          for="youtube_link"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          Youtube<br />リンク
        </label>
        <input
          type="text"
          v-model="youtube_link"
          id="youtube_link"
          class="form-control form-control-lg col-sm-7"
        />
      </div>
      <div class="row align-items-center mb-5">
        <label
          for="description"
          class="col-form-label col-form-label-lg text-center"
        >
          商品の説明
        </label>
        <br />
        <textarea
          name="description"
          id="description"
          rows="10"
          class="col-sm-12"
          v-model="description"
        />
      </div>
      <div class="row align-items-center mb-5">
        <label
          for="emotional_attachment"
          class="col-form-label col-form-label-lg text-center"
        >
          商品への思い入れ
        </label>
        <br />
        <textarea
          name="emotional_attachment"
          id="emotional_attachment"
          rows="10"
          class="col-sm-12"
          v-model="emotional_attachment"
        />
      </div>
      <div class="row align-items-center mb-5">
        <label
          for="delivery_charge"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          配送料の<br />負担
        </label>
        <select
          v-model="delivery_charge"
          id="delivery_charge"
          class="form-control form-control-lg col-sm-3"
        >
          <option
            v-for="item in DELIVERY_CHARGE_OPTION"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </option>
        </select>
        <label
          for="delivery_method"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          配送の方法
        </label>
        <select
          :value="delivery_method"
          id="delivery_method"
          class="form-control form-control-lg col-sm-3"
          disabled
        >
          <option
            v-for="item in DELIVERY_METHOD_OPTION"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </option>
        </select>
        <label
          for="delivery_origin_prefecture"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          発送元の<br />地域
        </label>
        <select
          v-model="delivery_origin_prefecture"
          id="delivery_origin_prefecture"
          class="form-control form-control-lg col-sm-3"
        >
          <option value="-1"></option>
          <option v-for="item in prefectures" :key="item.id" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="row align-items-center mb-5">
        <label
          for="days_to_delivery"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          発送までの<br />日数
        </label>
        <select
          v-model="days_to_delivery"
          id="days_to_delivery"
          class="form-control form-control-lg col-sm-3"
        >
          <option
            v-for="item in DAYS_TO_DELIVERY_OPTION"
            :key="item.value"
            :value="item.value"
          >
            {{ item.text }}
          </option>
        </select>
      </div>
      <div class="row align-items-center mb-5">
        <label
          for="price"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          値段
        </label>
        <input
          type="text"
          v-model="price"
          id="price"
          class="form-control form-control-lg col-sm-3"
        />
        <label
          for="commission_rate"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          販売<br />手数料率
        </label>
        <input
          type="text"
          v-model="commission_rate"
          id="commission_rate"
          class="form-control form-control-lg col-sm-3"
        />
      </div>
      <div class="row align-items-center mb-5">
        <label
          for="trial_price_1week"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          トライアル<br />料金1W
        </label>
        <input
          type="text"
          v-model="trial_price_1week"
          id="trial_price_1week"
          class="form-control form-control-lg col-sm-3"
        />
        <label
          for="trial_price_2week"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          トライアル<br />料金2W
        </label>
        <input
          type="text"
          v-model="trial_price_2week"
          id="trial_price_2week"
          class="form-control form-control-lg col-sm-3"
        />
        <label
          for="trial_price_4week"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          トライアル<br />料金4W
        </label>
        <input
          type="text"
          v-model="trial_price_4week"
          id="trial_price_4week"
          class="form-control form-control-lg col-sm-3"
        />
      </div>
      <div class="row align-items-center mb-5">
        <label
          for="commission"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          販売手数料
        </label>
        <input
          type="text"
          :value="'&yen;&nbsp;' + commission.toLocaleString()"
          id="commission"
          class="form-control form-control-lg col-sm-3"
          readonly
        />
        <label
          for="profit"
          class="col-form-label col-form-label-lg col-sm-1 text-center"
        >
          顧客<br />販売利益
        </label>
        <input
          type="text"
          :value="'&yen;&nbsp;' + profit.toLocaleString()"
          id="commission"
          class="form-control form-control-lg col-sm-3"
          readonly
        />
      </div>
      <div class="row m-0 justify-content-end">
        <button
          class="btn btn-primary btn-lg col-sm-1"
          @click="registerNewItem()"
          :disabled="is_axios_wait"
        >
          保存
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      name: "",
      user_id: "",
      status: 0,
      is_public: 1,
      item_category_id: -1,
      brand: "",
      type: "",
      color: "",
      body_type: "",
      pattern: "",
      condition_code: -1,
      youtube_link: "",
      description: "",
      emotional_attachment: "",
      delivery_charge: -1,
      delivery_method: -1,
      delivery_origin_prefecture: -1,
      days_to_delivery: -1,
      price: "",
      commission_rate: "",
      trial_price_1week: "",
      trial_price_2week: "",
      trial_price_4week: "",
      STATUS_OPTION: [
        { text: "", value: -1 },
        { text: "販売中", value: 0 },
        { text: "試奏中", value: 1 },
        { text: "売り切れ", value: 2 },
        { text: "下書き", value: 3 },
      ],
      IS_PUBLIC_OPTION: [
        { text: "", value: -1 },
        { text: "非公開", value: 0 },
        { text: "公開", value: 1 },
      ],
      CONDITION_CODE_OPTION: [
        { text: "", value: -1 },
        { text: "新品・未使用", value: 0 },
        { text: "未使用に近い", value: 1 },
        { text: "目立った傷や汚れなし", value: 2 },
        { text: "やや傷や汚れあり", value: 3 },
        { text: "傷や汚れあり", value: 4 },
        { text: "全体的に状態が悪い", value: 5 },
        { text: "ジャンク品", value: 6 },
      ],
      DELIVERY_CHARGE_OPTION: [
        { text: "", value: -1 },
        { text: "送料込み（出品者負担）", value: 0 },
        { text: "着払い（購入者負担）", value: 1 },
      ],
      DELIVERY_METHOD_OPTION: [
        { text: "", value: -1 },
        { text: "GANNON便", value: 0 },
        { text: "宅急便", value: 1 },
      ],
      DAYS_TO_DELIVERY_OPTION: [
        { text: "", value: -1 },
        { text: "１～２日で発送（Ships in 1-2 days）", value: 0 },
        { text: "２～３日で発送（Ships in 2-3 days）", value: 1 },
        { text: "４～７日で発送（Ships in 4-7 days）", value: 2 },
      ],
      item_categories: [],
      prefectures: [],
      is_axios_wait: false,
      images: [],
    };
  },
  async mounted() {
    this.userInfo = this.$store.getters["userInfo/userInfo"];

    await Promise.all([
      this.$axios.get(`/getItemCategories`, {
        headers: { Authorization: `Bearer ${this.userInfo.accessToken}` },
      }),
      this.$axios.get(`/getPrefectures`, {
        headers: { Authorization: `Bearer ${this.userInfo.accessToken}` },
      }),
    ])
      .then((responses) => {
        const item_categories_data = responses[0].data;
        const prefectures_data = responses[1].data;
        if (item_categories_data.status === "NG") {
          alert(item_categories_data.error_message);
          return;
        }
        if (prefectures_data.status === "NG") {
          alert(prefectures_data.error_message);
          return;
        }
        this.item_categories = item_categories_data.item_categories;
        this.prefectures = prefectures_data.prefectures;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  computed: {
    commission() {
      return this.price * this.commission_rate;
    },
    profit() {
      return this.price - this.commission;
    },
  },
  watch: {
    delivery_charge(value) {
      this.delivery_method = value;
    },
  },
  methods: {
    changeImages(event) {
      const newImages = Array.from(event.target.files);
      this.images = [];
      newImages.forEach((newImage, index) => {
        this.createImage(newImage, index);
      });
    },
    createImage(image, order) {
      const reader = new FileReader();
      reader.onload = () => {
        this.images.push({ image_src: reader.result, order: order });
      };
      reader.readAsDataURL(image);
    },
    registerNewItem() {
      const data = {
        user_id: this.user_id,
        name: this.name,
        status: this.status,
        is_public: this.is_public,
        item_category_id: this.item_category_id,
        brand: this.brand,
        type: this.type,
        color: this.color,
        body_type: this.body_type,
        pattern: this.pattern,
        condition_code: this.condition_code,
        youtube_link: this.youtube_link,
        description: this.description,
        emotional_attachment: this.emotional_attachment,
        delivery_charge: this.delivery_charge,
        delivery_method: this.delivery_method,
        delivery_origin_prefecture: this.delivery_origin_prefecture,
        days_to_delivery: this.days_to_delivery,
        price: this.price,
        commission_rate: this.commission_rate,
        commission: this.commission,
        profit: this.profit,
        trial_price_1week: this.trial_price_1week,
        trial_price_2week: this.trial_price_2week,
        trial_price_4week: this.trial_price_4week,
        images: this.images,
      };

      this.$axios
        .post(
          `/registerNewItem`,
          { data },
          { headers: { Authorization: `Bearer ${this.userInfo.accessToken}` } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }

          alert("登録しました");

          this.name = "";
          this.user_id = "";
          this.status = 0;
          this.is_public = 1;
          this.item_category_id = -1;
          this.brand = "";
          this.type = "";
          this.color = "";
          this.body_type = "";
          this.pattern = "";
          this.condition_code = -1;
          this.youtube_link = "";
          this.description = "";
          this.emotional_attachment = "";
          this.delivery_charge = -1;
          this.delivery_origin_prefecture = -1;
          this.days_to_delivery = -1;
          this.price = "";
          this.commission_rate = "";
          this.trial_price_1week = "";
          this.trial_price_2week = "";
          this.trial_price_4week = "";
          this.images = [];

          this.$refs.file.value = null;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
}
</style>
