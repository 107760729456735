<template>
  <div class="transaction">
    <div class="search-form">
      <div class="d-flex">
        <div class="serch-form-upper">
          <div class="form-row">
            <div class="form-item input-text">
              <label for="transaction_id" class="form-label">取引ID</label>
              <input
                type="text"
                v-model="transaction_id"
                id="transaction_id"
                class="form-input"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-item input-text">
              <label for="user_id" class="form-label">
                Buyer/Seller<br />
                ID
              </label>
              <input
                type="text"
                v-model="user_id"
                id="user_id"
                class="form-input"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-item input-text">
              <label for="user_name" class="form-label">
                Buyer/Seller<br />
                氏名
              </label>
              <input
                type="text"
                v-model="user_name"
                id="user_name"
                class="form-input"
              />
            </div>
          </div>
        </div>
        <div class="term-operation-wrapper">
          <div class="d-flex term-operation-content">
            <div class="term-operation-title">本日の期限対応</div>
            <div class="today-term-operation term-operation">
              {{ previous_day_count }}件
            </div>
          </div>
          <div class="d-flex term-operation-content">
            <div class="term-operation-title">明日の期限予定</div>
            <div class="tomorrow-term-operation term-operation">
              {{ current_day_count }}件
            </div>
          </div>
        </div>
      </div>
      <div class="form-row d-flex">
        <div class="form-item input-select">
          <label for="status_code" class="form-label">Status</label>
          <select
            name="status_code"
            id="status_code"
            class="form-select"
            v-model="status_code"
          >
            <option value="">未選択</option>
            <option value="01">配送準備中</option>
            <option value="02">配送中</option>
            <option value="03">配送受取通知待ち</option>
            <option value="04">試奏中</option>
            <option value="07">期間超過</option>
            <option value="08">試奏延長中</option>
            <option value="11">取引停止中</option>
            <option value="13">返送準備中</option>
            <option value="14">返送中</option>
            <option value="15">返送受取通知待ち</option>
            <option value="16">出品者評価待ち</option>
            <option value="17">購入者評価待ち</option>
            <option value="30">集荷待ち</option>
            <option value="90">支払い前</option>
            <option value="91">試奏延長支払い前</option>
            <option value="92">試奏後購入支払い前</option>
            <option value="99">取引終了</option>
          </select>
        </div>
        <div class="form-item input-select">
          <label for="step_alert" class="form-label">
            Step<br />
            Alert
          </label>
          <select
            name="step_alert"
            id="step_alert"
            class="form-select"
            v-model="step_alert"
          >
            <option value="">未選択</option>
            <option value="24">24</option>
            <option value="72">72</option>
          </select>
        </div>
      </div>
      <div class="form-row d-flex">
        <div class="form-item input-select">
          <label for="is_purchase" class="form-label">購入</label>
          <select
            name="is_purchase"
            id="is_purchase"
            class="form-select"
            v-model="is_purchase"
          >
            <option value="">未選択</option>
            <option value="0">─</option>
            <option value="1">●</option>
          </select>
        </div>
        <div class="form-item input-select">
          <label for="is_trial" class="form-label">試奏</label>
          <select
            name="is_trial"
            id="is_trial"
            class="form-select"
            v-model="is_trial"
          >
            <option value="">未選択</option>
            <option value="0">─</option>
            <option value="1">●</option>
          </select>
        </div>
        <div class="form-item input-select">
          <label for="is_trial_extend" class="form-label">試奏延長</label>
          <select
            name="is_trial_extend"
            id="is_trial_extend"
            class="form-select"
            v-model="is_trial_extend"
          >
            <option value="">未選択</option>
            <option value="0">─</option>
            <option value="1">●</option>
          </select>
        </div>
      </div>
      <div class="form-row d-flex">
        <div class="form-item input-select">
          <label for="expiration_date_from" class="form-label">期限日</label>
          <datepicker
            class="form-date"
            input-class-name="datepicker-input"
            id="expiration_date_from"
            v-model="expiration_date_from"
            locale="ja"
            :format="'yyyy-MM-dd'"
            :enable-time-picker="false"
            :auto-apply="true"
            :close-on-scroll="true"
          >
          </datepicker>
        </div>
        <div class="form-item input-select">
          <label for="expiration_date_to" class="form-label">～</label>
          <datepicker
            class="form-date"
            input-class-name="datepicker-input"
            id="expiration_date_to"
            v-model="expiration_date_to"
            locale="ja"
            :format="'yyyy-MM-dd'"
            :enable-time-picker="false"
            :auto-apply="true"
            :close-on-scroll="true"
          />
        </div>
        <div class="form-item input-select">
          <label for="overdue" class="form-label">期限超過</label>
          <select
            name="overdue"
            id="overdue"
            class="form-select"
            v-model="overdue"
          >
            <option value="">未選択</option>
            <option value="0">配送</option>
            <option value="1">試奏サービス</option>
            <option value="2">試奏延長</option>
            <option value="3">返送</option>
          </select>
        </div>
        <button @click="searchButtonClick" class="search-button">検索</button>
      </div>
    </div>
    <div class="transactions-wrapper">
      <table class="table" v-if="transactions.length">
        <thead class="thead-dark">
          <tr>
            <th>
              <input
                type="checkbox"
                name="transactions"
                id="transactions"
                value="transaction_all_check"
                v-model="transactions_all_check"
                @change="transactionsCheckChanged"
              />
            </th>
            <th>取引ID</th>
            <th>Buyer</th>
            <th>BuyerName</th>
            <th>Seller</th>
            <th>SellerName</th>
            <th>Status</th>
            <th>期限日</th>
            <th>StepAlert</th>
            <th>最終取引</th>
            <th>購入</th>
            <th>配送期限超過</th>
            <th>試奏サービス</th>
            <th>試奏サービス<br />期限超過</th>
            <th>試奏延長</th>
            <th>試奏延長<br />期限超過</th>
            <th>返送期限超過</th>
            <th>出品者評価</th>
            <th>購入者評価</th>
            <th>売上計</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="t in transactions" :key="t.transaction_id">
            <td>
              <input
                type="checkbox"
                name="transaction"
                :id="`check_transaction${t.transaction_id}`"
                v-model="transactions_check_list"
                :value="t.transaction_id"
              />
            </td>
            <td>
              <router-link
                :to="`/transactions/${t.transaction_id}`"
                target="_blank"
              >
                {{ t.transaction_id }}
              </router-link>
            </td>
            <td>{{ t.buyer_user_id }}</td>
            <td>{{ t.buyer_user_name }}</td>
            <td>{{ t.seller_user_id }}</td>
            <td>{{ t.seller_user_name }}</td>
            <td :class="{ 'color-red': ['11', '12'].includes(t.status_code) }">
              {{ t.status }}
            </td>
            <td>{{ dispData(t.expiration_date) }}</td>
            <td>{{ dispData(t.step_alert) }}</td>
            <td>{{ dispData(t.last_transaction) }}</td>
            <td>{{ dispData(t.is_purchase) }}</td>
            <td>{{ dispData(t.is_delivery_overdue) }}</td>
            <td>{{ dispData(t.trial) }}</td>
            <td>{{ dispData(t.is_trial_overdue) }}</td>
            <td>{{ dispData(t.trial_extend) }}</td>
            <td>{{ dispData(t.is_trial_extend_overdue) }}</td>
            <td>{{ dispData(t.is_return_overdue) }}</td>
            <td>
              {{
                t.seller_evaluation === null
                  ? "-"
                  : evaluation_list[t.seller_evaluation]
              }}
            </td>
            <td>
              {{
                t.buyer_evaluation === null
                  ? "-"
                  : evaluation_list[t.buyer_evaluation]
              }}
            </td>
            <td class="sales-total">
              &yen; {{ Number(t.sales_total).toLocaleString() }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="footer" v-if="page_count">
      <div class="footer-content">
        <div class="transactions-count">計{{ transactions_count }}件</div>
        <paginate
          v-model="page_num"
          :page-count="page_count"
          :prev-text="'<'"
          :next-text="'>'"
          :click-handler="clickPaginate"
          :container-class="'pagination'"
          :page-range="6"
          :hide-prev-next="true"
        />
        <div class="button-area">
          <button
            class="change-status-restart-button"
            @click="clickChangeStatusButton(1)"
            :disabled="isAxiosWait"
          >
            取引再開
          </button>
          <button
            class="change-status-stop-button"
            @click="clickChangeStatusButton(0)"
            :disabled="isAxiosWait"
          >
            取引停止
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate-next";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  data() {
    return {
      transactions: [],
      transactions_check_list: [],
      transactions_all_check: [],
      page_count: 0,
      page_num: 1,
      userInfo: {},
      previous_day_count: 0,
      current_day_count: 0,
      transaction_id: "",
      user_id: "",
      user_name: "",
      status_code: "",
      step_alert: "",
      is_purchase: "",
      is_trial: "",
      is_trial_extend: "",
      expiration_date_from: "",
      expiration_date_to: "",
      overdue: "",
      is_axios_wait: false,
      evaluation_list: ["よくなかった", "よかった"],
    };
  },
  components: {
    paginate: Paginate,
    datepicker: VueDatePicker,
  },
  mounted() {
    this.userInfo = this.$store.getters["userInfo/userInfo"];
    const today = new Date();
    const data = {};
    data.expiration_date = `${today.getFullYear()}-${
      today.getMonth() + 1
    }-${today.getDate()}`;

    this.$axios
      .post(
        `/getRecentlyExpirationCount`,
        { data },
        { headers: { Authorization: `Bearer ${this.userInfo.accessToken}` } }
      )
      .then((res) => {
        const { data } = res;
        if (data.status === "NG") {
          this.errorMessages = [data.error_message];
          return;
        }
        this.previous_day_count = data.previous_day_count;
        this.current_day_count = data.current_day_count;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isAxiosWait = false;
      });
  },
  computed: {},
  watch: {
    page_num(page_num) {
      this.searchSubmit(page_num);
    },
  },
  methods: {
    searchButtonClick() {
      this.page_num = 1;
      this.searchSubmit();
    },
    searchSubmit(page_num = 1) {
      this.resetValue();
      const transaction_ids =
        this.transaction_id === "" ? [] : this.transaction_id.split(/\s+/);
      const user_ids = this.user_id === "" ? [] : this.user_id.split(/\s+/);
      const data = {
        transaction_id: transaction_ids,
        user_id: user_ids,
        user_name: this.user_name,
        status_code: this.status_code,
        step_alert: this.step_alert,
        is_purchase: this.is_purchase,
        is_trial: this.is_trial,
        is_trial_extend: this.is_trial_extend,
        expiration_date_from: this.expiration_date_from,
        expiration_date_to: this.expiration_date_to,
        overdue: this.overdue,
        page_num: page_num,
      };

      this.isAxiosWait = true;
      this.$axios
        .post(
          `/getTransactionList`,
          { data },
          { headers: { Authorization: `Bearer ${this.userInfo.accessToken}` } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }
          this.transactions = data.transaction_list;
          this.transactions.forEach((transaction, index) => {
            const expiration_date = new Date(
              this.transactions[index].expiration_date
            );
            this.transactions[index].expiration_date = isNaN(expiration_date)
              ? ""
              : `${expiration_date.getFullYear()}/${
                  expiration_date.getMonth() + 1
                }/${expiration_date.getDate()}`;
          });
          this.transactions_count = data.total;
          this.page_count = data.last_page;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isAxiosWait = false;
        });
    },
    clickPaginate() {
      this.resetValue();
    },
    transactionsCheckChanged(e) {
      const transactions_id = [];
      if (e.target.checked) {
        this.transactions.forEach((t) => {
          transactions_id.push(t.transaction_id);
        });
      }
      this.transactions_check_list = transactions_id;
    },
    resetValue() {
      this.transactions_check_list = [];
      this.transactions_all_check = [];
    },
    clickChangeStatusButton(transaction_situation) {
      if (
        !confirm(
          `チェックした項目の取引を${
            transaction_situation ? "再開" : "停止"
          }します。よろしいですか？`
        )
      )
        return;
      if (!this.isValidChangeStatus(transaction_situation)) {
        alert(
          `チェックした項目に${
            transaction_situation ? "取引中" : "停止中"
          }のステータスが含まれています。再度確認してください。`
        );
        return;
      }
      const data = {
        transaction_id: this.transactions_check_list,
        transaction_situation,
      };

      this.isAxiosWait = true;
      this.$axios
        .post(
          `/updateTransactionSituation`,
          { data },
          { headers: { Authorization: `Bearer ${this.userInfo.accessToken}` } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
          } else {
            this.searchSubmit(this.page_num);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isAxiosWait = false;
        });
    },
    isValidChangeStatus(transaction_situation) {
      let isValid = true;
      this.transactions_check_list.forEach((checkedTransactionId) => {
        const checkedTransaction = this.transactions.find((transaction) => {
          return checkedTransactionId === transaction.transaction_id;
        });
        if (
          (transaction_situation &&
            !["11", "12"].includes(checkedTransaction.status_code)) ||
          (!transaction_situation &&
            ["11", "12"].includes(checkedTransaction.status_code))
        ) {
          isValid = false;
        }
      });
      return isValid;
    },
    dispData(data) {
      return data === null || data === "" ? "-" : data;
    },
  },
};
</script>

<style>
.datepicker-input {
  padding-block: 0;
  font-size: 2rem;
}
</style>

<style lang="scss" scoped>
.color-red {
  color: red;
}
.transaction {
  font-size: 3rem;
}
.d-flex {
  display: flex;
}
.search-form {
  padding: 1rem 2rem;
}
.serch-form-upper {
  width: 60%;

  .form-item {
    width: 100%;
  }
  .form-input {
    flex-grow: 1;
  }
}
.form-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.form-row > div:first-child > label:first-child {
  width: 15rem;
}
.form-row > .input-select:nth-child(3) > label {
  width: 9rem;
}
.input-text {
  width: 55rem;
}
.form-label {
  width: 4rem;
  font-size: 1.5rem;
}
.form-input {
  width: 40rem;
  font-size: 3rem;
}
.form-select,
.form-date {
  width: 20rem;
  height: 3rem;
  margin-right: 3rem;
  font-size: 2rem;
  text-align: center;
}
.term-operation-wrapper {
  margin-left: auto;
  margin-right: 10rem;
  font-size: 2.5rem;
  vertical-align: bottom;
}
.term-operation-content {
  align-items: end;
}
.term-operation-content:first-child {
  margin-bottom: 2rem;
}
.term-operation-title {
  margin-right: 2rem;
}
.term-operation {
  margin-left: auto;
  font-weight: bold;
}
.today-term-operation {
  color: red;
}
.search-button {
  margin-left: auto;
  padding: 0.5rem 3rem;
  height: 4rem;
  font-size: 2rem;
  border-radius: 0.5rem;
  background-color: #40f960;
  border-color: #239536;
}
.transactions-wrapper {
  width: 100%;
  margin: auto;
  margin-bottom: 2rem;
  overflow-x: scroll;
  font-size: 1.5rem;
  white-space: nowrap;
}
thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
tbody {
  text-align: center;
}
tbody tr:nth-child(2n) {
  background-color: #dfdfdf;
}
.pagination {
  margin: 2rem auto;
  justify-content: center;
}
.footer {
  position: sticky;
  bottom: 0;
  height: 9rem;
  background-color: white;
  border-top: 1px #646464 solid;
}
.footer-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.transactions-count {
  position: absolute;
  left: 3rem;
}
.button-area {
  font-size: 2rem;
}
.change-status-restart-button,
.change-status-stop-button {
  margin-right: 1rem;
  padding: 0.5rem 3rem;
}
.change-status-restart-button {
  background-color: #4264ff;
  border: 1px #1f48fd solid;
  color: white;
}
.change-status-stop-button {
  background-color: #ff3232;
  border: 1px #fd1f1f solid;
  color: white;
}
.sales-total {
  text-align: right;
}
</style>
