<template>
  <div class="inquiry-detail h4">
    <div class="display-3">
      {{
        `【${inquiry_details.category}】${inquiry_details.inquiry_id}: ${inquiry_details.title}`
      }}
    </div>
    <div class="row align-items-center">
      <li class="col-3">
        {{
          `${disp_data(inquiry_details.customer_user_id)}: ${disp_data(
            inquiry_details.customer_user_name
          )}`
        }}
      </li>
      <li class="col-3">商品ID: {{ inquiry_details.item_id }}</li>
      <li class="col-3">
        登録日: {{ getFormattedDateForDisplay(inquiry_details.created_date) }}
      </li>
      <div class="col-3 text-right">
        <div
          :class="[
            STATUS_BG_COLORS[status],
            'py-3 px-4 d-inline-block ml-auto',
          ]"
        >
          <label for="status" class="d-none">ステータス</label>
          <select
            id="status"
            class="form-control form-control-lg"
            v-model="status"
          >
            <option
              v-for="status_item in STATUS_OPTION"
              :key="status_item.value"
              :value="status_item.value"
            >
              {{ status_item.text }}
            </option>
          </select>
        </div>
        <button
          class="ml-5 py-2 px-5 btn btn-lg btn-primary"
          @click="clickedChangeStatusButton"
          :disabled="isDisabledStatusSaveButton"
        >
          保存
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <li class="py-3">{{ disp_data(inquiry_details.phone_number) }}</li>
        <li class="py-3">
          {{ disp_data(inquiry_details.email) }}
        </li>
      </div>
      <div class="col-3">
        <li class="py-3">担当者</li>
        <span class="display-4 pl-4">{{
          disp_data(inquiry_details.admin_user_name)
        }}</span>
      </div>
      <div class="col-3">
        <li class="py-3">次回対応予定日</li>
        <span class="display-4 pl-4">{{
          getFormattedDateForDisplay(inquiry_details.planned_date)
        }}</span>
      </div>
    </div>
    <div class="row border border-secondary p-3 mb-5 inquiry_image_wrapper">
      <div
        class="col-4 text-center"
        v-for="(image, index) in inquiry_details.images"
        :key="image"
      >
        <li>{{ image }}</li>
        <button
          class="btn btn-outline-light p-0"
          data-toggle="modal"
          data-target="#image-modal"
          @click="setModalImageIndex(index)"
        >
          <img
            :src="image"
            alt="問合せ画像"
            class="border border-secondary inquiry_image"
          />
        </button>
      </div>
      <div
        v-if="inquiry_details.images && inquiry_details.images.length === 0"
        class="d-flex align-items-center display-4"
      >
        添付画像なし
      </div>
    </div>
    <div
      class="row border border-secondary p-3 mb-5 text-break inquiry_details_content"
    >
      {{ inquiry_details.content }}
    </div>
    <!-- <template
      v-if="
        inquiry_details.return_contents &&
        inquiry_details.return_contents.length
      "
    >
      <div class="row mb-5">
        <div
          v-for="(return_content, index) in inquiry_details.return_contents"
          :key="index"
          class="border border-secondary border-left-0 border-right-0 p-3"
        >
          <div class="mb-1">
            <span class="display-4 mr-3">
              {{
                `${return_content.sender_user_name}${
                  return_content.is_admin === "1" ? "" : "様"
                } &#x21D2; ${return_content.reciever_user_name}${
                  return_content.is_admin === "1" ? "様" : ""
                }`
              }}
            </span>
            <span class="h1">
              {{ formatted_date(return_content.created_at) }}
            </span>
          </div>
          <div class="h4 send_content">{{ return_content.send_content }}</div>
        </div>
      </div>
    </template>
    <div class="mb-5">
      <div class="row from-group">
        <div class="col-9">
          <textarea
            v-model="inquiry_detail"
            name="inquiry_detail"
            id="inquiry_detail"
            rows="10"
            class="form-control form-control-lg inquiry_detail_textarea"
          />
        </div>
        <div class="col-3">
          <div class="form-group">
            <label for="planned_date">次回対応予定日</label>
            <datepicker
              input-class-name="form-control form-control-lg datepicker-input"
              id="planned_date"
              v-model="planned_date"
              locale="ja"
              :format="'yyyy-MM-dd'"
              :enable-time-picker="false"
              :auto-apply="true"
              :close-on-scroll="true"
            />
          </div>
          <div class="form-group">
            <label for="admin_user_id">担当者</label>
            <select
              name="admin_user_id"
              id="admin_user_id"
              class="form-control form-control-lg"
              v-model="admin_user_id"
            >
              <option value="0">未選択</option>
              <option
                v-for="admin_user in admin_users"
                :key="admin_user.user_id"
                :value="admin_user.user_id"
              >
                {{ admin_user.user_name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="status">ステータス</label>
            <select
              id="status"
              class="form-control form-control-lg"
              v-model="status"
            >
              <option
                v-for="status_item in STATUS_OPTION"
                :key="status_item.value"
                :value="status_item.value"
              >
                {{ status_item.text }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row justify-content-end">
        <button
          class="btn btn-primary btn-lg px-5 py-2 mr-5"
          @click="updateInquiryDetail(0)"
        >
          更新
        </button>
        <button
          class="btn btn-danger btn-lg px-5 py-2"
          @click="updateInquiryDetail(1)"
        >
          送信
        </button>
      </div>
    </div> -->
    <div
      class="modal"
      id="image-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="image-modal-label"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered image-modal-dialog"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-close-button-wrapper">
              <button
                class="btn border-0"
                data-toggle="modal"
                data-target="#image-modal"
              >
                <img
                  class="modal-close-button"
                  :src="CLOSE_ICON"
                  alt="閉じるボタン"
                />
              </button>
            </div>
            <div class="image-area">
              <button
                class="btn btn-outline-light"
                :disabled="isDisabledModalImagePrevButton"
                @click="setModalImageIndex(modal_image_index - 1)"
              >
                <img class="arrow_image" :src="LEFT_ARROW" alt="前の画像へ" />
              </button>
              <div class="modal-image-wrapper">
                <img
                  :src="
                    inquiry_details.images &&
                    inquiry_details.images[modal_image_index]
                  "
                  alt="問合せ画像"
                  class="modal-image"
                />
              </div>
              <button
                class="btn btn-outline-light"
                :disabled="isDisabledModalImageNextButton"
                @click="setModalImageIndex(modal_image_index + 1)"
              >
                <img class="arrow_image" :src="RIGHT_ARROW" alt="次の画像へ" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const RIGHT_ARROW = require("@/assets/images/right_arrow.png");
const LEFT_ARROW = require("@/assets/images/left_arrow.png");
const CLOSE_ICON = require("@/assets/images/close_icon.png");

export default {
  data() {
    return {
      inquiry_details: {},
      userInfo: {},
      admin_users: [],
      STATUS_BG_COLORS: [
        "bg-danger",
        "bg-primary",
        "bg-warning",
        "bg-secondary",
      ],
      inquiry_detail: "",
      planned_date: null,
      admin_user_id: 0,
      status: null,
      STATUS_OPTION: [
        { text: "連絡待ち", value: 0 },
        { text: "未対応", value: 1 },
        { text: "要対応", value: 2 },
        { text: "クローズ", value: 3 },
      ],
      modal_image_index: 0,
      RIGHT_ARROW,
      LEFT_ARROW,
      CLOSE_ICON,
    };
  },
  mounted() {
    this.userInfo = this.$store.getters["userInfo/userInfo"];
    this.getInquiryDetail();
  },
  computed: {
    disp_data() {
      return (value) => {
        return value ? value : "-";
      };
    },
    formatted_date() {
      return (date_str) => {
        const date = new Date(date_str);
        return `${date.getUTCFullYear()}/${
          date.getUTCMonth() + 1
        }/${date.getUTCDate()} ${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}`;
      };
    },
    isFormValid() {
      return this.inquiry_detail !== "" && [0, 1, 2, 3].includes(this.status);
    },
    isDisabledStatusSaveButton() {
      return this.status === this.inquiry_details.status_code;
    },
    isDisabledModalImagePrevButton() {
      return this.modal_image_index === 0;
    },
    isDisabledModalImageNextButton() {
      return (
        this.inquiry_details.images &&
        this.inquiry_details.images.length - 1 === this.modal_image_index
      );
    },
  },
  methods: {
    getInquiryDetail() {
      const data = {
        inquiry_id: this.$route.params.inquiry_id,
      };

      this.$axios
        .post(
          `/getInquiryDetails`,
          { data },
          { headers: { Authorization: `Bearer ${this.userInfo.accessToken}` } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }
          if (data.inquiry_details.length === 0) {
            alert("この問合せは存在しません");
            return;
          }
          this.inquiry_details = data.inquiry_details;
          this.status = this.inquiry_details.status_code;
          this.inquiry_details.customer_user_name
            ? this.inquiry_details.customer_user_name
            : "ゲストユーザー";
        })
        .catch((error) => {
          console.log(error);
        });

      this.$axios
        .get(`/getAdminUsers`, {
          headers: { Authorization: `Bearer ${this.userInfo.accessToken}` },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }
          this.admin_users = data.admin_users;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateInquiryDetail(is_send) {
      if (!this.isFormValid) {
        alert("必須項目が入力されていません");
        return;
      }
      if (
        !confirm(
          is_send
            ? "入力した内容をお客様へ送信します。※送信した内容は修正できません。よろしいですか？"
            : "入力した内容を「送信せず」に保存をします。よろしいですか？"
        )
      ) {
        return;
      }

      const data = {
        inquiry_id: this.$route.params.inquiry_id,
        inquiry_detail: this.inquiry_detail,
        planned_date: this.sendDateFormat(this.planned_date),
        admin_user_id: this.admin_user_id,
        is_send,
      };
      this.$axios
        .post(
          `/updateInquiryDetails`,
          { data },
          { headers: { Authorization: `Bearer ${this.userInfo.accessToken}` } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }
          this.getInquiryDetail();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clickedChangeStatusButton() {
      if (!confirm("ステータスを更新します。よろしいですか？")) {
        return;
      }

      const data = {
        inquiry_id: this.$route.params.inquiry_id,
        status_code: this.status,
      };
      this.$axios
        .patch(
          `/updateInquiryStatus`,
          { data },
          { headers: { Authorization: `Bearer ${this.userInfo.accessToken}` } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }
          this.getInquiryDetail();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendDateFormat(date) {
      if (date === null) return null;
      return date === null
        ? ""
        : new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            9,
            0,
            0
          );
    },
    getFormattedDateForDisplay(date_str) {
      const date_date_str = new Date(date_str);
      const date = new Date(
        date_date_str.getUTCFullYear(),
        date_date_str.getUTCMonth(),
        date_date_str.getUTCDate(),
        date_date_str.getUTCHours(),
        0,
        0
      );
      return date_str === null
        ? "-"
        : `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    setModalImageIndex(index) {
      this.modal_image_index =
        index < 0
          ? 0
          : index >= this.inquiry_details.images.length
          ? this.inquiry_details.images.length - 1
          : index;
    },
  },
};
</script>

<style lang="scss" scoped>
.inquiry-detail {
  width: 90%;
  margin: auto;
}
.inquiry_details_content {
  min-height: 13rem;
  max-height: 13rem;
  overflow-y: scroll;
}
.inquiry_image_wrapper {
  min-height: 10rem;
  overflow-x: scroll;
}
.inquiry_image {
  width: 20vw;
  height: calc(20vw * 9 / 16);
  object-fit: contain;
}
.send_content {
  white-space: pre-line;
}
.inquiry_detail_textarea {
  resize: none;
}
.modal-dialog {
  @media (min-width: 576px) {
    max-width: none;
  }
}
.modal-content {
  height: 80vh;
  width: 100vw;
}
.arrow_image {
  width: 50px;
}
.modal-body,
.image-area {
  height: 100%;
}
.image-area {
  display: flex;
}
.modal-image-wrapper {
  width: calc(100% - 100px);
}
.modal-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.modal-close-button-wrapper {
  position: absolute;
  top: -60px;
  right: 10px;
}
.modal-close-button {
  height: 50px;
  width: 50px;
}
</style>
