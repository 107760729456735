import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { userInfo } from "./modules/userInfo";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: { userInfo },
  plugins: [createPersistedState({ key: "wanonAdminStore" })],
});
