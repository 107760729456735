<template>
  <div class="transfer-applications">
    <div class="search-form col-sm-12 py-4 row">
      <div class="col-sm-7">
        <div class="row mb-4">
          <label for="id" class="col-sm-2 col-form-label col-form-label-lg">
            ID
          </label>
          <div class="col-sm-10">
            <input
              type="text"
              v-model="id"
              id="id"
              class="form-control form-control-lg col-sm-12"
            />
          </div>
        </div>
        <div class="row mb-4">
          <label for="name" class="col-sm-2 col-form-label col-form-label-lg">
            氏名
          </label>
          <div class="col-sm-10">
            <input
              type="text"
              v-model="name"
              id="name"
              class="form-control form-control-lg col-sm-12"
            />
          </div>
        </div>
        <div class="row mb-4">
          <label
            for="bank_name"
            class="col-sm-2 col-form-label col-form-label-lg"
          >
            銀行名
          </label>
          <div class="col-sm-4">
            <input
              type="text"
              v-model="bank_name"
              id="bank_name"
              class="form-control form-control-lg col-sm-12"
            />
          </div>
          <label
            for="bank_code"
            class="col-sm-2 col-form-label col-form-label-lg"
          >
            銀行コード
          </label>
          <div class="col-sm-4">
            <input
              type="text"
              v-model="bank_code"
              id="bank_code"
              class="form-control form-control-lg col-sm-12"
            />
          </div>
        </div>
        <div class="row mb-4">
          <label
            for="branch_name"
            class="col-sm-2 col-form-label col-form-label-lg"
          >
            支店名
          </label>
          <div class="col-sm-4">
            <input
              type="text"
              v-model="branch_name"
              id="branch_name"
              class="form-control form-control-lg col-sm-12"
            />
          </div>
          <label
            for="branch_code"
            class="col-sm-2 col-form-label col-form-label-lg"
          >
            支店コード
          </label>
          <div class="col-sm-4">
            <input
              type="text"
              v-model="branch_code"
              id="branch_code"
              class="form-control form-control-lg col-sm-12"
            />
          </div>
        </div>
        <div class="row mb-4">
          <label
            for="scheduled_transfer_date_from"
            class="col-sm-2 col-form-label col-form-label-lg"
          >
            振込予定日
          </label>
          <div class="col-sm-3">
            <datepicker
              input-class-name="form-control form-control-lg datepicker-input"
              id="scheduled_transfer_date_from"
              v-model="scheduled_transfer_date_from"
              locale="ja"
              :format="'yyyy-MM-dd'"
              :enable-time-picker="false"
              :auto-apply="true"
              :close-on-scroll="true"
            />
          </div>
          <label
            for="scheduled_transfer_date_to"
            class="col-sm-1 col-form-label col-form-label-lg"
          >
            ～
          </label>
          <div class="col-sm-3">
            <datepicker
              input-class-name="form-control form-control-lg datepicker-input"
              id="scheduled_transfer_date_to"
              v-model="scheduled_transfer_date_to"
              locale="ja"
              :format="'yyyy-MM-dd'"
              :enable-time-picker="false"
              :auto-apply="true"
              :close-on-scroll="true"
            />
          </div>
          <label for="status" class="col-sm-1 col-form-label col-form-label-lg">
            Status
          </label>
          <div class="col-sm-2">
            <select
              v-model="status"
              id="status"
              class="form-control form-control-lg col-sm-12"
            >
              <option
                v-for="status_item in STATUS_OPTION"
                :key="status_item.value"
                :value="status_item.value"
              >
                {{ status_item.text }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-sm-5">
        <div class="mb-3">
          <div class="row">
            <div class="col-sm-4 title col-form-label col-form-label-lg">
              {{ today_date }}の振込予定件数
            </div>
            <div class="col-sm-8 discription">
              {{ transfer_summary.today?.count }}件
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 title col-form-label col-form-label-lg">
              {{ today_date }}の振込金額合計
            </div>
            <div class="col-sm-8 discription">
              &yen;{{ transfer_summary.today?.total.toLocaleString() }}
            </div>
          </div>
        </div>
        <div class="mb-4">
          <div class="row">
            <div class="col-sm-4 title col-form-label col-form-label-lg">
              {{ tomorrow_date }}の振込予定件数
            </div>
            <div class="col-sm-8 discription">
              {{ transfer_summary.tomorrow?.count }}件
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4 title col-form-label col-form-label-lg">
              {{ tomorrow_date }}の振込金額合計
            </div>
            <div class="col-sm-8 discription">
              &yen;{{ transfer_summary.tomorrow?.total.toLocaleString() }}
            </div>
          </div>
        </div>
        <div class="row mb-5">
          <button
            class="btn btn-primary btn-lg ml-auto col-sm-3 mr-5"
            @click="getTransferSummary()"
          >
            サマリ取得
          </button>
        </div>
        <div class="row">
          <button
            class="btn btn-success btn-lg ml-auto mr-5 col-sm-3"
            @click="searchSubmit()"
          >
            検索
          </button>
        </div>
      </div>
    </div>
    <div class="transfer-applications-wrapper">
      <table class="table">
        <thead class="thead-dark">
          <tr>
            <th>
              <input
                type="checkbox"
                name="transfer-applications"
                id="transfer-applications"
                value="transfer_applications_check"
                v-model="transfer_applications_check"
                @change="transferApplicationsCheckChanged"
              />
            </th>
            <th>ID</th>
            <th>氏名</th>
            <th>振込予定日</th>
            <th>振込金額</th>
            <th>銀行</th>
            <th>銀行コード</th>
            <th>支店</th>
            <th>支店コード</th>
            <th>口座種別</th>
            <th>口座番号</th>
            <th>口座名義</th>
            <th>Status</th>
            <th>振込完了日</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="ta in transfer_applications"
            :key="ta.transfer_application_id"
          >
            <td>
              <input
                type="checkbox"
                name="trasnfer_application"
                :id="`check_transfer_application_${ta.transfer_application_id}`"
                v-model="transfer_applications_check_list"
                :value="ta.transfer_application_id"
              />
            </td>
            <td>{{ ta.transfer_application_id }}</td>
            <td>{{ ta.user_name }}</td>
            <td>{{ ta.application_expected_at }}</td>
            <td>&yen;{{ ta.amount_of_money.toLocaleString() }}</td>
            <td>{{ ta.bank_name }}</td>
            <td>{{ ta.bank_code }}</td>
            <td>{{ ta.branch_name }}</td>
            <td>{{ ta.branch_code }}</td>
            <td>{{ ta.account_type }}</td>
            <td>{{ ta.account_number }}</td>
            <td>{{ ta.pay_information_user_name }}</td>
            <td :class="{ 'font-weight-bold text-danger': ta.is_paid === 0 }">
              {{ PAID_STATUS_LIST[ta.is_paid] }}
            </td>
            <td>{{ ta.transferred_at }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="footer" v-if="page_count">
      <div class="footer-content">
        <div class="transfer-applications-count">
          計{{ transfer_applications_count }}件
        </div>
        <paginate
          v-model="page_num"
          :page-count="page_count"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'"
          :page-range="6"
          :hide-prev-next="true"
        />

        <button
          class="btn btn-warning btn-lg transfer-applications-update-button"
          @click="transferApplicationStatusUpdateButton()"
        >
          更新
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate-next";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  data() {
    return {
      today_date: "",
      tomorrow_date: "",
      transfer_summary: {},
      transfer_applications_check: false,
      transfer_applications_check_list: [],
      transfer_applications: [],
      transfer_applications_count: 0,
      id: "",
      name: "",
      bank_name: "",
      bank_code: "",
      branch_name: "",
      branch_code: "",
      scheduled_transfer_date_from: null,
      scheduled_transfer_date_to: null,
      status: -1,
      STATUS_OPTION: [
        { text: "", value: -1 },
        { text: "申請受付", value: 0 },
        { text: "振込済", value: 1 },
      ],
      PAID_STATUS_LIST: {
        0: "未",
        1: "済",
      },
      page_num: 1,
      page_count: 0,
    };
  },
  components: {
    paginate: Paginate,
    datepicker: VueDatePicker,
  },
  mounted() {
    this.userInfo = this.$store.getters["userInfo/userInfo"];
    const today = new Date();
    this.today_date = `${("0" + (today.getMonth() + 1)).slice(-2)}/${(
      "0" + today.getDate()
    ).slice(-2)}`;
    today.setDate(today.getDate() + 1);
    this.tomorrow_date = `${("0" + (today.getMonth() + 1)).slice(-2)}/${(
      "0" + today.getDate()
    ).slice(-2)}`;
  },
  computed: {},
  watch: {
    page_num(page_num) {
      this.searchSubmit(page_num);
    },
  },
  methods: {
    searchSubmit(page_num = 1) {
      const ids = this.id === "" ? [] : this.id.split(/\s+/);
      const names = this.name === "" ? [] : this.name.split(/\s+/);
      const bank_names =
        this.bank_name === "" ? [] : this.bank_name.split(/\s+/);
      const bank_codes =
        this.bank_code === "" ? [] : this.bank_code.split(/\s+/);
      const branch_names =
        this.branch_name === "" ? [] : this.branch_name.split(/\s+/);
      const branch_codes =
        this.branch_code === "" ? [] : this.branch_code.split(/\s+/);
      const data = {
        ids,
        names,
        bank_names,
        bank_codes,
        branch_names,
        branch_codes,
        status: this.status,
        scheduled_transfer_date_from: this.sendDateFormat(
          this.scheduled_transfer_date_from
        ),
        scheduled_transfer_date_to: this.sendDateFormat(
          this.scheduled_transfer_date_to
        ),
        page_num: page_num,
      };

      this.isAxiosWait = true;
      this.$axios
        .post(
          `/getTransferApplicationList`,
          { data },
          { headers: { Authorization: `Bearer ${this.userInfo.accessToken}` } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }
          this.transfer_applications = data.transfer_applications;
          this.transfer_applications_count = data.total;
          this.page_count = data.last_page;

          for (let i = 0; i < this.transfer_applications.length; i++) {
            this.transfer_applications[i].application_expected_at =
              this.transfer_applications[i].application_expected_at === null
                ? ""
                : this.getFormattedDateForDisplay(
                    this.transfer_applications[i].application_expected_at
                  );

            this.transfer_applications[i].transferred_at =
              this.transfer_applications[i].transferred_at === null
                ? ""
                : this.getFormattedDateForDisplay(
                    this.transfer_applications[i].transferred_at
                  );
          }
          this.transfer_applications_count = data.total;
          this.page_count = data.last_page;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isAxiosWait = false;
        });
    },
    sendDateFormat(date) {
      if (date === null) return "";
      const temp = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        9,
        0,
        0
      );
      console.log(temp);
      return date === null ? "" : temp;
    },
    getFormattedDateForDisplay(date_str) {
      const date_date_str = new Date(date_str);
      const date = new Date(
        date_date_str.getUTCFullYear(),
        date_date_str.getUTCMonth(),
        date_date_str.getUTCDate(),
        date_date_str.getUTCHours(),
        0,
        0
      );
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    transferApplicationsCheckChanged(e) {
      const transfer_applications_id = [];
      if (e.target.checked) {
        this.transfer_applications.forEach((ta) => {
          transfer_applications_id.push(ta.transfer_application_id);
        });
      }
      this.transfer_applications_check_list = transfer_applications_id;
    },
    transferApplicationStatusUpdateButton() {
      if (!confirm("振込ステータスを更新します。よろしいですか？")) {
        return;
      }
      if (this.transfer_applications_check_list.length === 0) {
        alert("更新する出金申請を選択してください。");
        return;
      }
      if (!this.isValidStatusUpdate()) {
        alert(
          "チェックした項目に済、未のステータスが含まれています。どちらかのみを選択してください。"
        );
        return;
      }

      const data = {
        transfer_application_ids: this.transfer_applications_check_list,
        status: this.getFirstTransferApplicationIsPaid() === 0 ? 1 : 0,
      };
      this.$axios
        .patch(
          `/updateTransferStatus`,
          { data },
          { headers: { Authorization: `Bearer ${this.userInfo.accessToken}` } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }
          this.transfer_applications_check = false;
          this.transfer_applications_check_list = [];
          this.searchSubmit(this.page_num);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    isValidStatusUpdate() {
      let is_valid = true;
      const now_is_paid = this.getFirstTransferApplicationIsPaid();
      this.transfer_applications_check_list.forEach(
        (checked_transfer_application_id) => {
          const checked_transfer_application = this.transfer_applications.find(
            (ta) => {
              return (
                checked_transfer_application_id === ta.transfer_application_id
              );
            }
          );

          if (now_is_paid !== checked_transfer_application.is_paid) {
            is_valid = false;
          }
        }
      );
      return is_valid;
    },
    getFirstTransferApplicationIsPaid() {
      const checked_first_transfer_application =
        this.transfer_applications.find((ta) => {
          return (
            this.transfer_applications_check_list[0] ===
            ta.transfer_application_id
          );
        });
      return checked_first_transfer_application.is_paid;
    },
    getTransferSummary() {
      if (!confirm("振込予定のサマリデータを取得します。よろしいですか？")) {
        return;
      }

      this.$axios
        .get(`/getTransferSummary`, {
          headers: { Authorization: `Bearer ${this.userInfo.accessToken}` },
        })
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }
          this.transfer_summary = data.transfer_summary;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isAxiosWait = false;
        });
    },
  },
};
</script>

<style lang="scss">
.datepicker-input {
  padding-inline: 35px 30px !important;
}
</style>

<style lang="scss" scoped>
.discription {
  border-bottom: 1px black solid;
  text-align: center;
}
.transfer-applications {
  font-size: 3rem;
}
.transfer-applications-wrapper {
  width: 100%;
  margin: auto;
  margin-bottom: 2rem;
  overflow-x: scroll;
  font-size: 1.5rem;
  white-space: nowrap;
}
.pagination {
  margin: 2rem 0;
  justify-content: center;
}
.footer {
  position: sticky;
  bottom: 0;
  height: 9rem;
  background-color: white;
  border-top: 1px #646464 solid;
}
.footer-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.transfer-applications-count {
  position: absolute;
  left: 3rem;
}
.transfer-applications-update-button {
  position: absolute;
  right: 3rem;
}
</style>
