export const userInfo = {
  namespaced: true,
  state: () => ({
    userId: "",
    loginId: "",
    accessToken: "",
  }),
  getters: {
    userInfo(state) {
      return {
        userId: state.userId,
        loginId: state.loginId,
        accessToken: state.accessToken,
      };
    },
    userId(state) {
      return state.userId;
    },
    loginId(state) {
      return state.loginId;
    },
    accessToken(state) {
      return state.accessToken;
    },
  },
  mutations: {
    login(state, userInfo) {
      const { userId, loginId, accessToken } = userInfo;
      state.userId = userId;
      state.loginId = loginId;
      state.accessToken = accessToken;
    },
  },
};
