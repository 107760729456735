<template>
  <div class="inquiries">
    <div class="search-form">
      <div class="form-group row">
        <div class="col-sm-6 row">
          <label
            for="inquiry_id"
            class="col-sm-2 col-form-label col-form-label-lg"
          >
            InquityID
          </label>
          <div class="col-sm-10">
            <input
              type="text"
              v-model="inquiry_id"
              id="inquiry_id"
              class="form-control form-control-lg col-sm-12"
            />
          </div>
        </div>
        <div class="col-sm-6 row">
          <label
            for="planned_date_from"
            class="col-sm-2 col-form-label col-form-label-lg"
          >
            対応予定日
          </label>
          <datepicker
            class="col-sm-4"
            input-class-name="form-control form-control-lg datepicker-input"
            id="planned_date_from"
            v-model="planned_date_from"
            locale="ja"
            :format="'yyyy-MM-dd'"
            :enable-time-picker="false"
            :auto-apply="true"
            :close-on-scroll="true"
          />
          <label
            for="planned_date_to"
            class="col-sm-2 col-form-label col-form-label-lg text-center"
          >
            ～
          </label>
          <datepicker
            class="col-sm-4"
            input-class-name="form-control form-control-lg datepicker-input"
            id="planned_date_to"
            v-model="planned_date_to"
            locale="ja"
            :format="'yyyy-MM-dd'"
            :enable-time-picker="false"
            :auto-apply="true"
            :close-on-scroll="true"
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-6 row">
          <label
            for="user_id"
            class="col-sm-2 col-form-label col-form-label-lg"
          >
            UserID
          </label>
          <div class="col-sm-10">
            <input
              type="text"
              v-model="user_id"
              id="user_id"
              class="form-control form-control-lg col-sm-12"
            />
          </div>
        </div>
        <div class="col-sm-6 row">
          <label
            for="created_date_from"
            class="col-sm-2 col-form-label col-form-label-lg"
          >
            登録日
          </label>
          <datepicker
            class="col-sm-4"
            input-class-name="form-control form-control-lg datepicker-input"
            id="created_date_from"
            v-model="created_date_from"
            locale="ja"
            :format="'yyyy-MM-dd'"
            :enable-time-picker="false"
            :auto-apply="true"
            :close-on-scroll="true"
          />
          <label
            for="created_date_to"
            class="col-sm-2 col-form-label col-form-label-lg text-center"
          >
            ～
          </label>
          <datepicker
            class="col-sm-4"
            input-class-name="form-control form-control-lg datepicker-input"
            id="created_date_to"
            v-model="created_date_to"
            locale="ja"
            :format="'yyyy-MM-dd'"
            :enable-time-picker="false"
            :auto-apply="true"
            :close-on-scroll="true"
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-6 row">
          <label
            for="item_id"
            class="col-sm-2 col-form-label col-form-label-lg"
          >
            商品ID
          </label>
          <div class="col-sm-10">
            <input
              type="text"
              v-model="item_id"
              id="item_id"
              class="form-control form-control-lg col-sm-12"
            />
          </div>
        </div>
        <div class="col-sm-6 row justify-content-end">
          <button
            class="btn btn-success btn-lg col-sm-3"
            @click="searchSubmit()"
          >
            検索
          </button>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-6 row">
          <label
            for="keyword"
            class="col-sm-2 col-form-label col-form-label-lg"
          >
            キーワード
          </label>
          <div class="col-sm-10">
            <input
              type="text"
              v-model="keyword"
              id="keyword"
              class="form-control form-control-lg col-sm-12"
            />
          </div>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-6 row">
          <label
            for="category"
            class="col-sm-2 col-form-label col-form-label-lg"
          >
            カテゴリ
          </label>
          <div class="col-sm-4">
            <select
              v-model="category"
              id="category"
              class="form-control form-control-lg col-sm-12"
            >
              <option
                v-for="category_item in CATEGORY_OPTION"
                :key="category_item.value"
                :value="category_item.value"
              >
                {{ category_item.text }}
              </option>
            </select>
          </div>
          <label for="status" class="col-sm-2 col-form-label col-form-label-lg">
            ステータス
          </label>
          <div class="col-sm-4">
            <select
              v-model="status"
              id="status"
              class="form-control form-control-lg col-sm-12"
            >
              <option
                v-for="status_item in STATUS_OPTION"
                :key="status_item.value"
                :value="status_item.value"
              >
                {{ status_item.text }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-6 row justify-content-end">
          <button class="btn btn-primary btn-lg col-sm-3">新規登録</button>
        </div>
      </div>
    </div>
    <div class="inquiries-wrapper">
      <table v-if="inquiries.length" class="table table-striped table-bordered">
        <thead class="thead-dark">
          <th>カテゴリ</th>
          <th>InquiryID</th>
          <th>ItemID</th>
          <th>UserID</th>
          <th>氏名</th>
          <th>タイトル</th>
          <th>登録日</th>
          <th>対応予定日</th>
          <th>ステータス</th>
          <th>担当者</th>
        </thead>
        <tbody class="text-left">
          <tr v-for="inquiry in inquiries" :key="inquiry.inquiry_id">
            <td>{{ inquiry.category }}</td>
            <td>
              <router-link
                :to="`/inquiries/${inquiry.inquiry_id}`"
                target="_blank"
              >
                {{ inquiry.inquiry_id }}
              </router-link>
            </td>
            <td>{{ inquiry.item_id }}</td>
            <td>{{ inquiry.customer_user_id }}</td>
            <td>{{ inquiry.customer_user_name }}</td>
            <td class="td-title">{{ inquiry.title }}</td>
            <td>{{ inquiry.created_date }}</td>
            <td>{{ inquiry.planned_date }}</td>
            <td
              :class="{
                'text-danger font-weight-bold': inquiry.status_code === 0,
              }"
            >
              {{ inquiry.status }}
            </td>
            <td>{{ inquiry.admin_user_name }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="footer" v-if="page_count">
      <div class="footer-content">
        <div class="inquiries-count">計{{ inquiries_count }}件</div>
        <paginate
          v-model="page_num"
          :page-count="page_count"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'"
          :page-range="6"
          :hide-prev-next="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate-next";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default {
  data() {
    return {
      inquiry_id: "",
      planned_date_from: null,
      planned_date_to: null,
      user_id: "",
      created_date_from: null,
      created_date_to: null,
      item_id: "",
      keyword: "",
      category: -1,
      status: -1,
      STATUS_OPTION: [
        { text: "", value: -1 },
        { text: "未対応", value: 0 },
        { text: "連絡待ち", value: 1 },
        { text: "要対応", value: 2 },
        { text: "クローズ", value: 3 },
      ],
      CATEGORY_OPTION: [
        { text: "", value: -1 },
        { text: "お問合せ", value: 0 },
        { text: "トラブル", value: 1 },
        { text: "ご意見", value: 2 },
        { text: "違反報告", value: 3 },
        { text: "進行中の取引について", value: 4 },
        { text: "ご連絡", value: 5 },
        { text: "その他", value: 9 },
      ],
      TITLE_MAX_LENGTH: 20,
      page_num: 1,
      page_count: 0,
      inquiries: [],
      inquiries_count: 0,
      userInfo: {},
    };
  },
  components: {
    paginate: Paginate,
    datepicker: VueDatePicker,
  },
  mounted() {
    this.userInfo = this.$store.getters["userInfo/userInfo"];
  },
  computed: {},
  watch: {
    page_num(page_num) {
      this.searchSubmit(page_num);
    },
  },
  methods: {
    searchButtonClick() {
      this.page_num = 1;
      this.searchSubmit();
    },
    searchSubmit(page_num = 1) {
      const inquiry_ids =
        this.inquiry_id === "" ? [] : this.inquiry_id.split(/\s+/);
      const user_ids = this.user_id === "" ? [] : this.user_id.split(/\s+/);
      const item_ids = this.item_id === "" ? [] : this.item_id.split(/\s+/);
      const keywords = this.keyword === "" ? [] : this.keyword.split(/\s+/);
      const data = {
        inquiry_id: inquiry_ids,
        user_id: user_ids,
        item_id: item_ids,
        keywords,
        category: this.category,
        status: this.status,
        planned_date_from: this.sendDateFormat(this.planned_date_from),
        planned_date_to: this.sendDateFormat(this.planned_date_to),
        created_date_from: this.sendDateFormat(this.created_date_from),
        created_date_to: this.sendDateFormat(this.created_date_to),
        page_num: page_num,
      };

      this.isAxiosWait = true;
      this.$axios
        .post(
          `/getInquiryList`,
          { data },
          { headers: { Authorization: `Bearer ${this.userInfo.accessToken}` } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }
          this.inquiries = data.inquiries;
          for (let i = 0; i < this.inquiries.length; i++) {
            this.inquiries[i].created_date =
              this.inquiries[i].created_date === null
                ? ""
                : this.getFormattedDateForDisplay(
                    this.inquiries[i].created_date
                  );

            this.inquiries[i].planned_date =
              this.inquiries[i].planned_date === null
                ? ""
                : this.getFormattedDateForDisplay(
                    this.inquiries[i].planned_date
                  );

            let title = this.inquiries[i].title;
            const title_list = [];
            for (
              let j = 0;
              j <
              Math.ceil(this.inquiries[i].title.length / this.TITLE_MAX_LENGTH);
              j++
            ) {
              title_list.push(
                title.substr(j * this.TITLE_MAX_LENGTH, this.TITLE_MAX_LENGTH)
              );
            }
            this.inquiries[i].title = title_list.join("\n");
          }
          this.inquiries_count = data.total;
          this.page_count = data.last_page;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isAxiosWait = false;
        });
    },
    sendDateFormat(date) {
      if (date === null) return "";
      const temp = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        9,
        0,
        0
      );
      console.log(temp);
      return date === null ? "" : temp;
    },
    getFormattedDateForDisplay(date_str) {
      const date_date_str = new Date(date_str);
      const date = new Date(
        date_date_str.getUTCFullYear(),
        date_date_str.getUTCMonth(),
        date_date_str.getUTCDate(),
        date_date_str.getUTCHours() - 9,
        0,
        0
      );
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
  },
};
</script>

<style lang="scss">
.datepicker-input {
  padding-inline: 35px 30px !important;
}
</style>

<style lang="scss" scoped>
.inquiries {
  font-size: 3rem;
}
.search-form {
  padding: 1rem 2rem;
}
.inquiries-wrapper {
  width: 100%;
  margin: auto;
  margin-bottom: 2rem;
  overflow-x: scroll;
  font-size: 1.5rem;
  white-space: nowrap;
}
thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.pagination {
  margin: 2rem auto;
  justify-content: center;
}
.footer {
  position: sticky;
  bottom: 0;
  height: 9rem;
  background-color: white;
  border-top: 1px #646464 solid;
}
.footer-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inquiries-count {
  position: absolute;
  left: 3rem;
}
.td-title {
  white-space: pre-line;
}
</style>
