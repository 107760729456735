<template>
  <div>
    <h1>メッセージ管理</h1>
  </div>
</template>

<style lang="scss" scoped>
.search-form {
  padding: 1rem 2rem;
}
.form-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.input-text {
  width: 55rem;
}
.form-label {
  font-size: 1.5rem;
}
.form-input {
  width: 40rem;
  font-size: 3rem;
}
.form-select {
  width: 8rem;
  font-size: 3rem;
  text-align: center;
}
</style>

<script>
export default {
  data() {
    return {
      userInfo: {},
      transaction_id: "",
    };
  },
  mounted() {
    this.userInfo = this.$store.getters["userInfo/userInfo"];
  },
  computed: {},
  methods: {},
};
</script>
