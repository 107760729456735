<template>
  <login-header />
  <login-page />
</template>

<script>
import LoginPage from "@/templates/LoginPage.vue";
import LoginHeader from "@/templates/LoginHeader.vue";

export default {
  components: {
    LoginHeader,
    LoginPage,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>
