<template>
  <div class="item-detail h4 py-5">
    <div class="row mb-5">
      <div class="col-1 title">{{ item_details.id }}</div>
      <div class="col-7 content">{{ item_details.name }}</div>
      <div class="col-1 title">ユーザID</div>
      <div class="col-3 content">{{ item_details.user_id }}</div>
    </div>
    <div class="row mb-5">
      <div class="col-1 title">ステータス</div>
      <div class="col-3 content">{{ STATUS_OPTIONS[item_details.status] }}</div>
      <div class="col-1 title">公開<br />ステータス</div>
      <div class="col-3 content">
        {{ IS_PUBLIC_OPTIONS[item_details.is_public] }}
      </div>
    </div>
    <div class="row">
      <div class="col-1 title">商品画像</div>
      <div class="col-12 image-wrapper">
        <img
          v-for="image in item_details.images"
          :key="image"
          :src="image"
          alt="商品画像"
          class="image"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-1 title">商品種類</div>
      <div class="col-3 content">{{ item_details.item_category_name }}</div>
      <div class="col-1 title">ブランド</div>
      <div class="col-3 content">{{ item_details.brand }}</div>
      <div class="col-1 title">タイプ</div>
      <div class="col-3 content">{{ item_details.type }}</div>
    </div>
    <div class="row">
      <div class="col-1 title">カラー</div>
      <div class="col-3 content">{{ item_details.color }}</div>
      <div class="col-1 title">ボディタイプ</div>
      <div class="col-3 content">{{ item_details.body_type }}</div>
      <div class="col-1 title">柄</div>
      <div class="col-3 content">{{ item_details.pattern }}</div>
    </div>
    <div class="row">
      <div class="col-1 title">商品の状態</div>
      <div class="col-3 content">{{ item_details.condition }}</div>
      <div class="col-1 title">Youtube<br />リンク</div>
      <div class="col-7 content">{{ item_details.youtube_link }}</div>
    </div>
    <div class="row">
      <div class="pre-title col-2">商品の説明</div>
      <div class="pre-text p-2 col-12">
        {{ item_details.description }}
      </div>
    </div>
    <div class="row">
      <div class="pre-title col-2">商品への思い入れ</div>
      <div class="pre-text p-2 col-12">
        {{ item_details.emotional_attachment }}
      </div>
    </div>
    <div class="row">
      <div class="col-1 title">配送料の負担</div>
      <div class="col-3 content">{{ item_details.delivery_charge }}</div>
      <div class="col-1 title">配送の方法</div>
      <div class="col-3 content">{{ item_details.delivery_method }}</div>
      <div class="col-1 title">発送元の地域</div>
      <div class="col-3 content">
        {{ item_details.delivery_prefecture_name }}
      </div>
    </div>
    <div class="row">
      <div class="col-1 title">発送までの<br />日数</div>
      <div class="col-3 content">{{ item_details.days_to_delivery }}</div>
    </div>
    <div class="row">
      <!-- TODO: カンマ3ケタ区切り, 単位 -->
      <div class="col-1 title">値段</div>
      <div class="col-3 content">
        {{ "&yen;&nbsp;" + item_details.price?.toLocaleString() }}
      </div>
      <div class="col-1 title">販売手数料率</div>
      <div class="col-3 content">
        {{ item_details.commission_rate?.toLocaleString() + "%" }}
      </div>
    </div>
    <div class="row">
      <div class="col-1 title">トライアル<br />料金1W</div>
      <div class="col-3 content">
        {{
          "&yen;&nbsp;" +
          Number(item_details.trial_price_1week)?.toLocaleString()
        }}
      </div>
      <div class="col-1 title">トライアル<br />料金2W</div>
      <div class="col-3 content">
        {{
          "&yen;&nbsp;" +
          Number(item_details.trial_price_2week)?.toLocaleString()
        }}
      </div>
      <div class="col-1 title">トライアル<br />料金4W</div>
      <div class="col-3 content">
        {{
          "&yen;&nbsp;" +
          Number(item_details.trial_price_4week)?.toLocaleString()
        }}
      </div>
    </div>
    <div class="row">
      <div class="col-1 title">販売手数料</div>
      <div class="col-3 content">
        {{ "&yen;&nbsp;" + item_details.commission?.toLocaleString() }}
      </div>
      <div class="col-1 title">顧客<br />販売利益</div>
      <div class="col-3 content">
        {{ "&yen;&nbsp;" + item_details.profit?.toLocaleString() }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item_details: {},
      user_info: {},
      STATUS_OPTIONS: {
        0: "販売中",
        1: "試奏中",
        2: "売り切れ",
        3: "下書き",
      },
      IS_PUBLIC_OPTIONS: {
        0: "停止中",
        1: "公開中",
      },
    };
  },
  mounted() {
    this.user_info = this.$store.getters["userInfo/userInfo"];
    this.getItemDetails();
  },
  computed: {},
  methods: {
    getItemDetails() {
      const data = {
        item_id: this.$route.params.item_id,
      };

      this.$axios
        .get(`/getItemDetails`, {
          headers: { Authorization: `Bearer ${this.user_info.accessToken}` },
          params: data,
        })
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }
          if (data.item_details.length === 0) {
            alert("この商品は存在しません");
            return;
          }
          const item_details = data.item_details[0];
          item_details.images = item_details.images
            ? item_details.images.split(",")
            : [];
          this.item_details = item_details;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-detail {
  width: 90%;
  margin: auto;
}
.title,
.pre-title,
.content {
  padding-block: 1rem;
}
.title,
.pre-title {
  font-weight: bold;
}
.title,
.content {
  display: flex;
  align-items: end;
}
.title {
  text-align: center;
  justify-content: center;
}
.content {
  justify-content: start;
  border-bottom: 1px #aaa solid;
}
.image-wrapper {
  padding: 2rem;
  border: 1px #aaa solid;
}
.image {
  height: 20rem;
  width: 20rem;
  margin: 1rem 2rem;
  object-fit: contain;
}
.pre-text {
  height: 20rem;
  overflow-y: scroll;
  border: 1px #aaa solid;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
