<template>
  <div class="user-detail h3 p-5 mx-auto">
    <div class="user-detail-area">
      <div class="row align-items-center mb-5">
        <div class="col-4 row align-items-center">
          <div class="font-weight-bold col-2 text-right">
            {{ user_details.id }}:
          </div>
          <div class="border-bottom col-10">{{ user_details.name }}</div>
        </div>
        <div class="col-4 row align-items-center">
          <div class="font-weight-bold col-2 text-right">売上金</div>
          <div class="border-bottom col-10">
            &yen;&nbsp;{{ formatted_proceeds }}
          </div>
        </div>
        <div class="col-4 row align-items-center">
          <div class="font-weight-bold col-3 text-right">販売手数料</div>
          <input type="text" v-model="commission" /><span>&nbsp;%</span>
        </div>
      </div>
      <div class="row align-items-center mb-5">
        <div class="col-12 row align-items-center">
          <div class="font-weight-bold col-1 text-right">現住所</div>
          <div class="border-bottom col-11">{{ user_details.address }}</div>
        </div>
      </div>
      <div class="row align-items-center mb-5">
        <div class="col-12 row align-items-end">
          <div class="font-weight-bold col-1 text-center">
            発送元<br />お届け先住所
          </div>
          <div class="border-bottom col-11 row mx-0 align-items-end">
            {{ user_details.delivery_address }}
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-5">
        <div class="col-5 row align-items-center">
          <div class="font-weight-bold col-2 text-right">電話番号</div>
          <div class="border-bottom col-10">{{ dispPhoneNumber }}</div>
        </div>
        <div class="col-7 row align-items-center">
          <div class="font-weight-bold col-2 text-right">メールアドレス</div>
          <div class="border-bottom col-10">{{ user_details.email }}</div>
        </div>
      </div>
      <div class="row align-items-center mb-5">
        <div class="col-3 row align-items-center">
          <div class="font-weight-bold col-4 text-right">本人確認</div>
          <select
            name="identified_status"
            id="identified_status"
            v-model="identified_status"
          >
            <option value="0">未確認</option>
            <option value="1">確認中</option>
            <option value="2">確認済</option>
          </select>
        </div>
        <div class="col-3 row align-items-center">
          <div class="font-weight-bold col-5 text-right">アカウント状態</div>
          <select
            name="is_suspension"
            id="is_suspension"
            v-model="is_suspension"
          >
            <option value="0">通常</option>
            <option value="1">停止</option>
          </select>
        </div>
      </div>
      <div class="row align-items-center mb-5 justify-content-between">
        <div class="col-4 row align-items-center">
          <div class="font-weight-bold col-4 text-right">ハンドルネーム</div>
          <div class="border-bottom col-8">{{ user_details.handle_name }}</div>
        </div>
        <div class="col-2 row align-items-center">
          <div class="font-weight-bold col-6 text-right">評価数</div>
          <div class="border-bottom col-6">
            {{ user_details.evaluation_count }}
          </div>
        </div>
        <div class="col-2 row align-items-center">
          <div class="font-weight-bold col-8 text-right">平均評価値</div>
          <div class="border-bottom col-4">
            {{ formatted_good_evaluation_ratio }}
          </div>
        </div>
      </div>
      <div class="row align-items-center mb-5">
        <div class="col-12">
          <div class="font-weight-bold">プロフィール自己紹介文</div>
          <pre class="border comment">{{ user_details.comment }}</pre>
        </div>
      </div>
      <div class="row m-0 justify-content-end">
        <button
          class="btn btn-primary btn-lg col-sm-1"
          @click="saveUserDetailInfo()"
          :disabled="is_axios_wait"
        >
          保存
        </button>
      </div>
    </div>
    <div class="user-note-area">
      <div
        v-for="user_note in user_notes"
        :key="user_note.note_id"
        class="border border-secondary border-top-0 border-right-0 border-left-0 mb-5"
      >
        <div class="row align-items-center font-weight-bold mb-3">
          <div class="col">◆{{ user_note.note_category }}</div>
          <div class="col text-right">
            {{ formatted_date(user_note.created_at) }}
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col">
            <pre>{{ user_note.note_content }}</pre>
          </div>
        </div>
      </div>
      <div class="user-note-form">
        <textarea
          v-model="note_content"
          name="note_content"
          id="note_content"
          rows="10"
          class="form-control form-control-lg mb-5"
        />
        <div class="user-note-form-button-area row justify-content-end">
          <div class="col-2">
            <select
              v-model="note_category_id"
              name="note_category_id"
              id="note_category_id"
              class="form-control form-control-lg"
            >
              <option
                v-for="note_category in NOTE_CATEGORIES_OPTION"
                :key="note_category.value"
                :value="note_category.value"
              >
                {{ note_category.text }}
              </option>
            </select>
          </div>
          <button
            class="btn btn-primary btn-lg col-1"
            @click="saveUserNote()"
            :disabled="is_axios_wait"
          >
            保存
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user_details: {},
      user_notes: [],
      userInfo: {},
      commission: 0,
      identified_status: 0,
      is_suspension: 0,
      is_axios_wait: false,
      NOTE_CATEGORIES_OPTION: [
        { value: "01", text: "補償費支払い" },
        { value: "02", text: "決済失敗" },
        { value: "03", text: "期限超過" },
        { value: "04", text: "迷惑行為" },
        { value: "05", text: "アカウント停止履歴" },
        { value: "06", text: "商品報告履歴" },
        { value: "99", text: "その他" },
      ],
      note_content: "",
      note_category_id: "01",
    };
  },
  mounted() {
    this.userInfo = this.$store.getters["userInfo/userInfo"];
    this.getUserDetail();
  },
  computed: {
    dispPhoneNumber() {
      return !this.user_details.phone_number
        ? ""
        : `${this.user_details.phone_number.substr(
            0,
            3
          )}-${this.user_details.phone_number.substr(
            3,
            4
          )}-${this.user_details.phone_number.substr(7)}`;
    },
    formatted_date() {
      return (date_str) => {
        const date = new Date(date_str);
        return `${date.getUTCFullYear()}/${(
          "0" +
          (date.getUTCMonth() + 1)
        ).slice(-2)}/${("0" + date.getUTCDate()).slice(-2)} ${(
          "0" + date.getUTCHours()
        ).slice(-2)}:${("0" + date.getUTCMinutes()).slice(-2)}:${(
          "0" + date.getUTCSeconds()
        ).slice(-2)}`;
      };
    },
    formatted_proceeds() {
      return this.user_details.proceeds?.toLocaleString();
    },
    formatted_good_evaluation_ratio() {
      return this.user_details.good_evaluation_ratio === undefined
        ? ""
        : Math.round(this.user_details.good_evaluation_ratio * 10) / 10;
    },
  },
  methods: {
    getUserDetail() {
      const data = {
        user_id: this.$route.params.user_id,
      };

      this.$axios
        .post(
          `/getUserDetails`,
          { data },
          { headers: { Authorization: `Bearer ${this.userInfo.accessToken}` } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }
          if (data.user_details.length === 0) {
            alert("このユーザは存在しません");
            return;
          }
          this.user_details = data.user_details;
          this.commission = this.user_details.commission;
          this.identified_status = this.user_details.identified_status;
          this.is_suspension = this.user_details.is_suspension;
        })
        .catch((error) => {
          console.log(error);
        });

      this.$axios
        .post(
          `/getUserNotes`,
          { data },
          { headers: { Authorization: `Bearer ${this.userInfo.accessToken}` } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }
          this.user_notes = data.user_notes;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveUserDetailInfo() {
      if (!confirm("ユーザーの詳細情報を変更します。よろしいですか？")) {
        return;
      }

      if (
        this.commission === this.user_details.commission &&
        this.identified_status === this.user_details.identified_status &&
        this.is_suspension === this.user_details.is_suspension
      ) {
        alert("内容が変更されていません。");
        return;
      }

      const data = {
        user_id: this.user_details.id,
        commission: this.commission,
        identified_status: this.identified_status,
        is_suspension: this.is_suspension,
      };

      this.is_axios_wait = true;
      this.$axios
        .patch(
          `/updateUserDetailInfo`,
          { data },
          { headers: { Authorization: `Bearer ${this.userInfo.accessToken}` } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }
          this.note_content = "";
          this.note_category_id = "01";

          this.getUserDetail();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.is_axios_wait = false;
        });
    },
    saveUserNote() {
      if (!confirm("ユーザーの個別情報を登録します。よろしいですか？")) {
        return;
      }

      if (this.note_content === "") {
        alert("ノート内容を入力してください。");
        return;
      }

      const data = {
        user_id: this.user_details.id,
        note_category_id: this.note_category_id,
        note_content: this.note_content,
      };

      this.is_axios_wait = true;
      this.$axios
        .post(
          `/insertUserNote`,
          { data },
          { headers: { Authorization: `Bearer ${this.userInfo.accessToken}` } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }
          this.note_category_id = "01";
          this.note_content = "";

          this.getUserDetail();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.is_axios_wait = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-detail-area {
  margin-bottom: 5rem;
}
.comment {
  height: 15rem;
  overflow-y: scroll;
}
.pre {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
</style>
