<template>
  <div class="inquiries">
    <div class="search-form form-group">
      <div class="col-sm-12 row mt-4">
        <label for="user_id" class="col-sm-2 col-form-label col-form-label-lg">
          ID
        </label>
        <div class="col-sm-5">
          <input
            type="text"
            v-model="user_id"
            id="user_id"
            class="form-control form-control-lg col-sm-12"
          />
        </div>
      </div>
      <div class="col-sm-12 row mt-4">
        <label
          for="user_name"
          class="col-sm-2 col-form-label col-form-label-lg"
        >
          氏名
        </label>
        <div class="col-sm-5">
          <input
            type="text"
            v-model="user_name"
            id="user_name"
            class="form-control form-control-lg col-sm-12"
          />
        </div>
      </div>
      <div class="col-sm-12 row mt-4">
        <label
          for="phone_number"
          class="col-sm-2 col-form-label col-form-label-lg"
        >
          電話番号
        </label>
        <div class="col-sm-5">
          <input
            type="text"
            v-model="phone_number"
            id="phone_number"
            class="form-control form-control-lg col-sm-12"
          />
        </div>
      </div>
      <div class="col-sm-12 row mt-4">
        <label for="email" class="col-sm-2 col-form-label col-form-label-lg">
          メールアドレス
        </label>
        <div class="col-sm-5">
          <input
            type="text"
            v-model="email"
            id="email"
            class="form-control form-control-lg col-sm-12"
          />
        </div>
      </div>
      <div class="col-sm-12 row mt-4">
        <label
          for="prefecture_id"
          class="col-sm-2 col-form-label col-form-label-lg"
        >
          都道府県
        </label>
        <div class="col-sm-2">
          <select
            v-model="prefecture_id"
            id="prefecture_id"
            class="form-control form-control-lg"
          >
            <option
              v-for="prefecture_item in PREFECTURE_OPTION"
              :key="prefecture_item.value"
              :value="prefecture_item.value"
            >
              {{ prefecture_item.text }}
            </option>
          </select>
        </div>
        <label
          for="is_suspension"
          class="col-sm-1 col-form-label col-form-label-lg"
        >
          アカウント<br />停止状態
        </label>
        <div class="col-sm-2">
          <select
            v-model="is_suspension"
            id="is_suspension"
            class="form-control form-control-lg"
          >
            <option
              v-for="is_suspension_item in IS_SUSPENSION_OPTION"
              :key="is_suspension_item.value"
              :value="is_suspension_item.value"
            >
              {{ is_suspension_item.text }}
            </option>
          </select>
        </div>
        <label
          for="identified_status"
          class="col-sm-1 col-form-label col-form-label-lg"
        >
          本人確認
        </label>
        <div class="col-sm-2">
          <select
            v-model="identified_status"
            id="identified_status"
            class="form-control form-control-lg"
          >
            <option
              v-for="identified_status_item in IDENTIFIED_STATUS_OPTION"
              :key="identified_status_item.value"
              :value="identified_status_item.value"
            >
              {{ identified_status_item.text }}
            </option>
          </select>
        </div>
        <div class="col-sm-2 row justify-content-end align-items-start">
          <button class="btn btn-success btn-lg px-5" @click="searchSubmit()">
            検索
          </button>
        </div>
      </div>
    </div>
    <div class="users-wrapper">
      <table v-if="users.length" class="table table-striped table-bordered">
        <thead class="thead-dark">
          <th>ID</th>
          <th>氏名</th>
          <th>ハンドルネーム</th>
          <th>現住所</th>
          <th>電話番号</th>
          <th>メールアドレス</th>
          <th>本人確認</th>
          <th>アカウント</th>
          <th>販売手数料</th>
        </thead>
        <tbody class="text-left">
          <tr v-for="user in users" :key="user.id">
            <td>
              <router-link :to="`/users/${user.id}`" target="_blank">
                {{ user.id }}
              </router-link>
            </td>
            <td>
              {{ user.name }}
            </td>
            <td>{{ user.handle_name }}</td>
            <td>{{ user.address }}</td>
            <td>{{ dispPhoneNumber(user.phone_number) }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.identified_status === 2 ? "〇" : "×" }}</td>
            <td :class="{ 'text-danger': user.is_suspension }">
              {{ disp_is_suspensions[user.is_suspension] }}
            </td>
            <td>{{ user.commission }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="footer" v-if="page_count">
      <div class="footer-content">
        <div class="inquiries-count">計{{ users_count }}件</div>
        <paginate
          v-model="page_num"
          :page-count="page_count"
          :prev-text="'<'"
          :next-text="'>'"
          :container-class="'pagination'"
          :page-range="6"
          :hide-prev-next="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate-next";

export default {
  data() {
    return {
      user_id: "",
      user_name: "",
      phone_number: "",
      email: "",
      prefecture_id: -1,
      is_suspension: -1,
      identified_status: -1,
      page_num: 1,
      page_count: 0,
      users_count: 0,
      users: [],
      userInfo: {},
      PREFECTURE_OPTION: [
        { text: "未選択", value: -1 },
        { text: "北海道", value: 1 },
        { text: "青森県", value: 2 },
        { text: "岩手県", value: 3 },
        { text: "宮城県", value: 4 },
        { text: "秋田県", value: 5 },
        { text: "山形県", value: 6 },
        { text: "福島県", value: 7 },
        { text: "茨城県", value: 8 },
        { text: "栃木県", value: 9 },
        { text: "群馬県", value: 10 },
        { text: "埼玉県", value: 11 },
        { text: "千葉県", value: 12 },
        { text: "東京都", value: 13 },
        { text: "神奈川県", value: 14 },
        { text: "新潟県", value: 15 },
        { text: "富山県", value: 16 },
        { text: "石川県", value: 17 },
        { text: "福井県", value: 18 },
        { text: "山梨県", value: 19 },
        { text: "長野県", value: 20 },
        { text: "岐阜県", value: 21 },
        { text: "静岡県", value: 22 },
        { text: "愛知県", value: 23 },
        { text: "三重県", value: 24 },
        { text: "滋賀県", value: 25 },
        { text: "京都府", value: 26 },
        { text: "大阪府", value: 27 },
        { text: "兵庫県", value: 28 },
        { text: "奈良県", value: 29 },
        { text: "和歌山県", value: 30 },
        { text: "鳥取県", value: 31 },
        { text: "島根県", value: 32 },
        { text: "岡山県", value: 33 },
        { text: "広島県", value: 34 },
        { text: "山口県", value: 35 },
        { text: "徳島県", value: 36 },
        { text: "香川県", value: 37 },
        { text: "愛媛県", value: 38 },
        { text: "高知県", value: 39 },
        { text: "福岡県", value: 40 },
        { text: "佐賀県", value: 41 },
        { text: "長崎県", value: 42 },
        { text: "熊本県", value: 43 },
        { text: "大分県", value: 44 },
        { text: "宮崎県", value: 45 },
        { text: "鹿児島県", value: 46 },
        { text: "沖縄県", value: 47 },
      ],
      IS_SUSPENSION_OPTION: [
        { text: "未選択", value: -1 },
        { text: "通常", value: 0 },
        { text: "停止", value: 1 },
      ],
      IDENTIFIED_STATUS_OPTION: [
        { text: "未選択", value: -1 },
        { text: "未確認", value: 0 },
        { text: "確認中", value: 1 },
        { text: "確認済", value: 2 },
      ],
      disp_is_suspensions: ["通常", "停止"],
      disp_identified_status: ["×", "△", "〇"],
    };
  },
  components: {
    Paginate,
  },
  mounted() {
    this.userInfo = this.$store.getters["userInfo/userInfo"];
  },
  computed: {
    dispPhoneNumber() {
      return function (phone_number) {
        return phone_number === ""
          ? ""
          : `${phone_number.substr(0, 3)}-${phone_number.substr(
              3,
              4
            )}-${phone_number.substr(7)}`;
      };
    },
  },
  watch: {
    page_num(page_num) {
      this.searchSubmit(page_num);
    },
  },
  methods: {
    searchButtonClick() {
      this.page_num = 1;
      this.searchSubmit();
    },
    searchSubmit(page_num = 1) {
      const data = {
        user_id: this.user_id,
        user_name: this.user_name,
        phone_number: this.phone_number,
        email: this.email,
        prefecture_id: this.prefecture_id,
        is_suspension: this.is_suspension,
        identified_status: this.identified_status,
        page_num: page_num,
      };

      this.isAxiosWait = true;
      this.$axios
        .get(`/getUserList`, {
          headers: { Authorization: `Bearer ${this.userInfo.accessToken}` },
          params: data,
        })
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }
          this.users = data.users;
          this.users_count = data.total;
          this.page_count = data.last_page;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isAxiosWait = false;
        });
    },
  },
};
</script>

<style lang="scss">
.datepicker-input {
  padding-inline: 35px 30px !important;
}
</style>

<style lang="scss" scoped>
.inquiries {
  font-size: 3rem;
}
.search-form {
  padding: 1rem 2rem;
}
.inquiries-wrapper {
  width: 100%;
  margin: auto;
  margin-bottom: 2rem;
  overflow-x: scroll;
  font-size: 1.5rem;
  white-space: nowrap;
}
thead {
  position: sticky;
  top: 0;
  z-index: 1;
}
.pagination {
  margin: 2rem auto;
  justify-content: center;
}
.footer {
  position: sticky;
  bottom: 0;
  height: 9rem;
  background-color: white;
  border-top: 1px #646464 solid;
}
.footer-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inquiries-count {
  position: absolute;
  left: 3rem;
}
.td-title {
  white-space: pre-line;
}
.users-wrapper {
  width: 100%;
  margin: auto;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  white-space: nowrap;
}
</style>
