<template>
  <form @submit="loginSubmit" class="login-form">
    <div class="error-message-list">
      <p
        v-for="(errorMessage, index) in errorMessages"
        :key="index"
        class="error-message"
      >
        {{ errorMessage }}
      </p>
    </div>
    <input-form
      label="ログインID"
      id="login-id"
      :value="loginId"
      :propChangeValue="changeLoginId"
      :labelClassName="['input-form-label']"
      :inputClassName="['input-form-input']"
    />
    <password-form
      label="パスワード"
      id="password"
      :value="password"
      :propChangeValue="changePassword"
      :labelClassName="['input-form-label']"
      :inputClassName="['input-form-input']"
    />
    <button type="submit" class="submit-button" :disabled="isAxiosWait">
      ログイン
    </button>
  </form>
</template>

<style lang="scss" scoped>
.error-message-list {
  color: red;
  font-size: 2rem;
  font-weight: bold;
}
.error-message {
  margin: 0.5rem;
}
.login-form {
  text-align: center;
}
.input-form {
  margin-bottom: 2rem;
}
.input-form-label {
  font-size: 1.5rem;
}
.submit-button {
  font-size: 2rem;
  padding: 0.5rem 1rem;
  background-color: #3d40fc;
  color: white;
  border: none;
  border-radius: 1rem;

  &:disabled {
    opacity: 0.6;
  }
}
</style>

<script>
import jwt_decode from "jwt-decode";
import InputForm from "@/molecures/InputForm.vue";
import PasswordForm from "@/molecures/PasswordForm.vue";

export default {
  components: {
    InputForm,
    PasswordForm,
  },
  data() {
    return {
      loginId: "",
      password: "",
      errorMessages: [],
      isAxiosWait: false,
    };
  },
  methods: {
    loginSubmit(e) {
      e.preventDefault();
      if (!this.validForm()) {
        return false;
      }

      const data = {
        login_id: this.loginId,
        password: this.password,
      };
      this.isAxiosWait = true;
      this.$axios
        .post(`/sigininadmin`, { data })
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            this.errorMessages = [data.error_message];
            return;
          }
          const jwt_decoded = jwt_decode(data.access_token);
          const userInfo = {};
          userInfo.userId = jwt_decoded.user_id;
          userInfo.loginId = jwt_decoded.login_id;
          userInfo.accessToken = data.access_token;
          this.$store.commit("userInfo/login", userInfo);
          this.$router.push("/transactions");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isAxiosWait = false;
        });
    },
    changeLoginId(value) {
      this.loginId = value;
    },
    changePassword(value) {
      this.password = value;
    },
    validForm() {
      this.errorMessages = [];
      if (!this.loginId) {
        this.errorMessages.push("ログインIDを入力してください。");
      }
      if (!this.password) {
        this.errorMessages.push("パスワードを入力してください。");
      }

      return !this.errorMessages.length;
    },
  },
};
</script>
