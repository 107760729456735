<template>
  <div class="input-form">
    <label :for="id" :class="['input-label', ...labelClassName]">
      {{ label }}
    </label>
    <br />
    <text-field
      :class="inputClassName"
      :id="id"
      :value="value"
      :changeValue="changeValue"
    />
  </div>
</template>

<style lang="scss" scoped>
.input-form {
  text-align: left;
}
</style>

<script>
import TextField from "@/atoms/TextField";

export default {
  components: {
    TextField,
  },
  props: {
    label: String,
    id: String,
    labelClassName: {
      type: Array,
      default: () => [],
    },
    inputClassName: {
      type: Array,
      default: () => [],
    },
    value: String,
    propChangeValue: Function,
    required: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    changeValue(e) {
      this.propChangeValue(e.target.value);
    },
  },
};
</script>
