import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router/index";
import store from "./store";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

const app = createApp(App);
app.use(router);
app.use(store);
console.log(process.env.VUE_APP_ADMIN_API_ENDPOINT);
axios.defaults.baseURL = process.env.VUE_APP_ADMIN_API_ENDPOINT;
app.config.globalProperties.$axios = axios;
app.mount("#app");
