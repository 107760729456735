<template>
  <input
    :type="type"
    :id="id"
    :class="['atoms-input', ...className]"
    :value="value"
    @input="changeValue"
  />
</template>

<style lang="scss" scoped>
.atoms-input {
  width: 100%;
  box-sizing: border-box;
  font-size: 3rem;
}
</style>

<script>
export default {
  props: {
    id: String,
    type: {
      type: String,
      default: "text",
    },
    className: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
    changeValue: Function,
  },
  methods: {},
};
</script>
