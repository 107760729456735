<template>
  <div class="login-page">
    <login-form />
  </div>
</template>

<style lang="scss" scoped>
.login-page {
  width: 50%;
  margin: auto;
}
</style>

<script>
import LoginForm from "@/organisms/LoginForm.vue";

export default {
  components: {
    LoginForm,
  },
};
</script>
