<template>
  <div class="input-form">
    <label :for="id" :class="['password-label', ...labelClassName]">
      {{ label }}
    </label>
    <div class="password-input-wrapper">
      <text-field
        :id="id"
        :class="inputClassName"
        :value="value"
        v-if="isDisplayablePassword"
        :changeValue="changeValue"
      />
      <text-field
        :id="id"
        :class="inputClassName"
        :value="value"
        :type="'password'"
        v-else
        :changeValue="changeValue"
      />
      <button
        type="button"
        :class="['eye-button', isDisplayablePassword ? 'eye-slash' : 'eye']"
        @click="setIsDisplayablePassword(!isDisplayablePassword)"
      >
        <img
          :src="isDisplayablePassword ? IconLoginEyeSlash : IconLoginEye"
          alt="パスワード非表示アイコン"
          class="eye-icon"
        />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.input-form {
  text-align: left;
}
.password-input-wrapper {
  position: relative;
}
.password-input {
  width: 100%;
  padding-right: 4.5rem;
  box-sizing: border-box;
}
.eye-button {
  position: absolute;
  top: 0;
  right: 0.5rem;
  bottom: 0;
  padding: 0;
  background-color: inherit;
  border: none;
}
.eye-icon {
  font-size: 3rem;
  height: 4rem;
  width: 4rem;
}
</style>

<script>
import TextField from "@/atoms/TextField";

export default {
  components: {
    TextField,
  },
  props: {
    label: String,
    id: String,
    labelClassName: {
      type: Array,
      default: () => [],
    },
    inputClassName: {
      type: Array,
      default: () => [],
    },
    value: String,
    propChangeValue: Function,
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      IconLoginEyeSlash: require("@/assets/images/login_eye_slash_icon.png"),
      IconLoginEye: require("@/assets/images/login_eye_icon.png"),
      isDisplayablePassword: false,
    };
  },
  methods: {
    changeValue(e) {
      this.propChangeValue(e.target.value);
    },
    setIsDisplayablePassword(isDisplayablePassword) {
      this.isDisplayablePassword = isDisplayablePassword;
    },
  },
};
</script>
