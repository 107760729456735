<template>
  <div class="page">
    <div class="menu">
      <h1 class="menu-header">WANON ADMIN</h1>
      <div class="menu-item">
        <a
          href="/inquiries"
          :class="[
            'menu-link',
            { 'current-menu-link': $route.name === 'InquiriesPage' },
          ]"
        >
          問合せ管理
        </a>
      </div>
      <div class="menu-item">
        <a
          href="/transactions"
          :class="[
            'menu-link',
            { 'current-menu-link': $route.name === 'TransactionsPage' },
          ]"
        >
          取引管理
        </a>
      </div>
      <div class="menu-item">
        <a
          href="/transfer-applications"
          :class="[
            'menu-link',
            { 'current-menu-link': $route.name === 'TransferApplicationsPage' },
          ]"
        >
          出金申請管理
        </a>
      </div>
      <div class="menu-item">
        <a :class="['menu-link disabled']">メッセージ管理</a>
      </div>
      <div class="menu-item">
        <a
          href="/users"
          :class="[
            'menu-link',
            { 'current-menu-link': $route.name === 'UsersPage' },
          ]"
        >
          顧客管理
        </a>
      </div>
      <div class="menu-item">
        <a
          href="/items"
          :class="[
            'menu-link',
            { 'current-menu-link': $route.name === 'ItemsPage' },
          ]"
        >
          商品管理
        </a>
      </div>
      <div class="menu-item">
        <a :class="['menu-link disabled']">売上管理</a>
      </div>
      <div class="menu-item">
        <a :class="['menu-link disabled']">ユーザ管理</a>
      </div>
      <div class="menu-item">
        <a :class="['menu-link disabled']">お知らせ管理</a>
      </div>
      <div class="menu-item">
        <a :class="['menu-link disabled']">ガイド管理</a>
      </div>
      <div class="menu-item">
        <a :class="['menu-link disabled']">利用規約管理</a>
      </div>
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.page {
  display: flex;
  width: 100%;
  height: 100%;
}
.menu {
  background-color: #f8cab0;
  height: 100%;
  width: 22rem;
  border-right: 1px black solid;
  box-sizing: border-box;
}
.menu-header {
  padding: 1rem 3rem;
}
.menu-item {
  padding: 1rem;
  border-top: 1px black solid;

  &:last-child {
    border-bottom: 1px black solid;
  }
}
.menu-link {
  font-size: 1.5rem;
  color: #222;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}
.current-menu-link {
  font-weight: bold;
}
.content {
  flex-grow: 1;
  overflow-y: scroll;
}
.disabled {
  cursor: pointer;
  color: #666;
}
</style>

<script>
export default {
  data() {
    return {
      userInfo: {},
    };
  },
  mounted() {
    this.userInfo = this.$store.getters["userInfo/userInfo"];
  },
  computed: {},
  methods: {},
};
</script>
