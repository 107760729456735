<template>
  <div class="transaction-detail">
    <div class="detail-row page-title">
      <div>取引詳細画面【{{ transaction.transaction_id }}】</div>
      <div
        :class="{
          'color-red bold': ['11', '12'].includes(transaction.status_code),
        }"
      >
        {{ transaction.status_code }}：{{ transaction.status }}
      </div>
    </div>
    <div class="detail-row">
      <div class="detail-item">
        <div class="detail-title user-id-title">
          <div>購入者</div>
          <div>{{ transaction.buyer_user_id }}</div>
        </div>
        <div class="detail-content">
          {{ transaction.buyer_user_name }}
        </div>
      </div>
      <div class="detail-item">
        <div class="detail-title user-id-title">
          <div>出品者</div>
          <div>{{ transaction.seller_user_id }}</div>
        </div>
        <div class="detail-content">
          {{ transaction.seller_user_name }}
        </div>
      </div>
      <div class="detail-item border-none"></div>
    </div>
    <div class="detail-row">
      <div class="detail-item">
        <div class="detail-title">
          <div>購入者ハンドルネーム</div>
        </div>
        <div class="detail-content">
          {{ transaction.buyer_handle_name }}
        </div>
      </div>
      <div class="detail-item">
        <div class="detail-title">
          <div>出品者ハンドルネーム</div>
        </div>
        <div class="detail-content">
          {{ transaction.seller_handle_name }}
        </div>
      </div>
      <div class="detail-item">
        <div class="detail-title">
          <div>商品名</div>
        </div>
        <div class="detail-content">
          {{ transaction.item_name }}
        </div>
      </div>
    </div>
    <div class="detail-row">
      <div class="detail-item">
        <div class="detail-title">
          <div>期限日</div>
        </div>
        <div class="detail-content">
          {{ dispData(transaction.expiration_date) }}
        </div>
      </div>
      <div class="detail-item">
        <div class="detail-title">
          <div>StepAlert</div>
        </div>
        <div class="detail-content">
          {{ dispData(transaction.step_alert) }}
        </div>
      </div>
      <div class="detail-item">
        <div class="detail-title">
          <div>最終取引</div>
        </div>
        <div class="detail-content">
          {{ transaction.last_transaction }}
        </div>
      </div>
    </div>
    <div class="detail-row">
      <div class="detail-item">
        <div class="detail-title">
          <div>試奏</div>
        </div>
        <div class="detail-content">
          {{ dispData(transaction.trial) }}
        </div>
      </div>
      <div class="detail-item">
        <div class="detail-title">
          <div>試奏延長</div>
        </div>
        <div class="detail-content">
          {{ dispData(transaction.trial_extend) }}
        </div>
      </div>
      <div class="detail-item">
        <div class="detail-title">
          <div>購入</div>
        </div>
        <div class="detail-content">
          {{ dispData(transaction.is_purchase) }}
        </div>
      </div>
    </div>
    <div class="detail-row">
      <div class="detail-item">
        <div class="detail-title">
          <div>試奏期限超過</div>
        </div>
        <div class="detail-content">
          {{ dispData(transaction.is_trial_overdue) }}
        </div>
      </div>
      <div class="detail-item">
        <div class="detail-title">
          <div>試奏延長期限超過</div>
        </div>
        <div class="detail-content">
          {{ dispData(transaction.is_trial_extend_overdue) }}
        </div>
      </div>
      <div class="detail-item border-none"></div>
    </div>
    <div class="detail-row">
      <div class="detail-item">
        <div class="detail-title">
          <div>配送期限超過</div>
        </div>
        <div class="detail-content">
          {{ dispData(transaction.is_delivery_overdue) }}
        </div>
      </div>
      <div class="detail-item">
        <div class="detail-title">
          <div>返送期限超過</div>
        </div>
        <div class="detail-content">
          {{ dispData(transaction.is_return_overdue) }}
        </div>
      </div>
      <div class="detail-item border-none"></div>
    </div>
    <div class="detail-row">
      <div class="detail-item">
        <div class="detail-title">
          <div>購入者評価</div>
        </div>
        <div class="detail-content">
          {{
            transaction.buyer_evaluation === null
              ? "-"
              : evaluation_list[transaction.buyer_evaluation]
          }}
        </div>
      </div>
      <div class="detail-item">
        <div class="detail-title">
          <div>出品者評価</div>
        </div>
        <div class="detail-content">
          {{
            transaction.seller_evaluation === null
              ? "-"
              : evaluation_list[transaction.seller_evaluation]
          }}
        </div>
      </div>
    </div>
    <div class="detail-row">
      <div class="detail-item">
        <div class="detail-title">
          <div>販売利益合計</div>
        </div>
        <div class="detail-content">
          &yen; {{ Number(transaction.user_total_profit).toLocaleString() }}
        </div>
      </div>
      <div class="detail-item">
        <div class="detail-title">
          <div>総額</div>
        </div>
        <div class="detail-content">
          &yen; {{ Number(transaction.user_total_price).toLocaleString() }}
        </div>
      </div>
      <div class="detail-item">
        <div class="detail-title">
          <div>手数料計</div>
        </div>
        <div class="detail-content">
          &yen; {{ Number(transaction.wanon_sales_total).toLocaleString() }}
        </div>
      </div>
    </div>
    <div class="detail-row">
      <div class="detail-item border-none">
        <div class="detail-title">
          <div>取引ステータス変更</div>
        </div>
        <div class="detail-content">
          <select
            name="status_code"
            id="status_code"
            class="form-select"
            v-model="status_code"
          >
            <option value="01">０１：配送準備中</option>
            <option value="02">０２：配送中</option>
            <option value="03">０３：配送受取通知待ち</option>
            <option value="04">０４：試奏中</option>
            <option value="07">０７：期間超過</option>
            <option value="08">０８：試奏延長中</option>
            <option value="11">１１：取引停止中</option>
            <option value="13">１３：返送準備中</option>
            <option value="14">１４：返送中</option>
            <option value="15">１５：返送受取通知待ち</option>
            <option value="16">１６：出品者評価待ち</option>
            <option value="17">１７：購入者評価待ち</option>
            <option value="30">３０：集荷待ち</option>
            <option value="90">９０：支払い前</option>
            <option value="91">９１：試奏延長支払い前</option>
            <option value="92">９２：試奏後購入支払い前</option>
            <option value="99">９９：取引終了</option>
          </select>
        </div>
      </div>
      <div class="detail-item border-none button-area">
        <button
          @click="cancelButtonClick"
          class="cancel-button"
          :disabled="is_axios_wait"
        >
          キャンセル
        </button>
        <button
          @click="submitButtonClick"
          class="submit-button"
          :disabled="is_axios_wait"
        >
          決定
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      transaction: {},
      userInfo: {},
      status_code: "",
      is_axios_wait: false,
      evaluation_list: ["よくなかった", "よかった"],
    };
  },
  mounted() {
    this.userInfo = this.$store.getters["userInfo/userInfo"];
    this.getTransactionDetail();
  },
  computed: {},
  methods: {
    getTransactionDetail() {
      const data = {
        transaction_id: this.$route.params.transaction_id,
      };

      this.$axios
        .post(
          `/getTransactionDetails`,
          { data },
          { headers: { Authorization: `Bearer ${this.userInfo.accessToken}` } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }
          if (data.transaction_detail.length === 0) {
            alert("この取引データは存在しません");
            this.$router.push("/transactions");
          }

          this.transaction = data.transaction_detail[0];

          const expiration_date = new Date(this.transaction.expiration_date);
          const day_list = ["日", "月", "火", "水", "木", "金", "土"];
          this.transaction.expiration_date = isNaN(expiration_date)
            ? ""
            : `${expiration_date.getFullYear()}/${
                expiration_date.getMonth() + 1
              }/${expiration_date.getDate()}(${
                day_list[expiration_date.getDay()]
              })`;
          this.status_code = this.transaction.status_code;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancelButtonClick() {
      window.close();
    },
    submitButtonClick() {
      if (!confirm("【重要】取引ステータスを変更します。よろしいですか？")) {
        return;
      }
      if (!this.isValidChangeStatus()) {
        alert("取引ステータスが変更されていません。");
        return;
      }

      const data = {
        transaction_id: this.transaction.transaction_id,
        before_transaction_status: this.transaction.status_code,
        after_transaction_status: this.status_code,
      };

      this.is_axios_wait = true;
      this.$axios
        .post(
          `/updateTransactionStatus`,
          { data },
          { headers: { Authorization: `Bearer ${this.userInfo.accessToken}` } }
        )
        .then((res) => {
          const { data } = res;
          if (data.status === "NG") {
            alert(data.error_message);
            return;
          }

          this.getTransactionDetail();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.is_axios_wait = false;
        });
    },
    dispData(data) {
      return data == null || data == 0 ? "-" : data == 1 ? "●" : data;
    },
    isValidChangeStatus() {
      return this.status_code !== this.transaction.status_code;
    },
  },
};
</script>

<style lang="scss" scoped>
.color-red {
  color: red;
}
.bold {
  font-weight: bold;
}
.transaction-detail {
  width: 90%;
  margin: auto;
}
.page-title {
  margin-top: 2rem;
  font-size: 3.5rem;
}
.detail-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.detail-item {
  width: 30%;
  border-bottom: 1px black solid;
}
.border-none {
  border: none;
}
.detail-title {
  font-size: 1.5rem;
}
.user-id-title {
  display: flex;
}
.user-id-title > div:first-child {
  margin-right: 4rem;
}
.detail-content {
  height: 4rem;
  font-size: 2.5rem;
}
.button-area {
  display: flex;
  align-items: end;
  font-size: 1.5rem;
}
.submit-button,
.cancel-button {
  width: 40%;
  height: 4rem;
}
.submit-button {
  background-color: #ff3232;
  border: 1px #fd1f1f solid;
  color: white;
}
.cancel-button {
  margin-right: 1.5rem;
}
</style>
